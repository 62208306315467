<!--创建/编辑活动-->
<script>
    import serverConfig from "@/utils/config/index.js";
    import {imageUrl} from "@/utils/config/commonUrl.js";
    import { clockPresetsList,clockPresetsDel,
        clockPresetsDetail,clockPresetsAdd,
        clockPresetsUpdate,activityDetail,updateActivity,updateSignList,
        getCoverimgTemplate,getSignList,getProjectManageAdmin} from "@/utils/api/build";
    import Layout from "../../layouts/main";
    import appConfig from "@/app.config";

    import axios from 'axios';
    import router from "../../../router";

    var dayjs = require('dayjs')

    export default {
        page: {
            title: "创建接龙",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout },
        data() {
            return {
                title: "Basic Tables",
                items: [
                    {
                        text: "Tables",
                        href: "/"
                    },
                    {
                        text: "Basic",
                        active: true
                    }
                ],
                drawer: true,
                direction: 'rtl',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                value: '',
                checked: true,
                value1: '',
                input: '',
                radio: '1',
                dialogImageUrl: '',
                dialogVisible: false,
                fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
                value2: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
                candidateList: [],// 选中的周几
                value3: '',

                map: null,
                point: null,
                markerPoint:{
                    address: '',

                },
                location: { lng: 116.403963, lat: 39.915119 },  // 地图中心点
                zoom: 19,  // 地图缩放级别
                markLocation: {  // 标记点的经纬度
                    lng: 116.403963, lat: 39.915119
                },
                form: {
                    address: '', // 详细地址
                    addrPoint: { // 详细地址经纬度
                        lng: 0,
                        lat: 0
                    }
                },
                mk: '', // Marker实例
                value4: '',

                addressDialog:false,
                addressIdx:null,

                poiname: '', //地址名称
                longitude: '', //纬度
                latitude: '', //经度


                // -----------------------------------------------------------------------------
                // 基础设置
                isType: '106', //创建活动的类型
                groupId: '', //群组id
                // 详细信息
                activityDetail:{
                    name:'',
                    content: '',
                },
                modularList: [], // 模块列表
                modular1: [], // 模块列表
                indexIdx: 0,
                commonDisabled: false, // 是否可以编辑
                formDrawerShow: false,
                actStartAndEndTime: '',
                // 表单项 类别
                formList:[
                    {
                        label: '姓名',
                        type: 0,
                    },
                    {
                        label: '手机号',
                        type: 1,
                    },
                    {
                        label: '数量',
                        type: 2,
                    },
                    {
                        label: '水印拍照',
                        type: 23,
                    },
                    {
                        label: '日期/时间',
                        type: 3,
                    },
                    {
                        label: '图片',
                        type: 4,
                    },
                    {
                        label: '身份证',
                        type: 5,
                    },
                    // {
                    //     label: '时间',
                    //     type: 6,
                    // },
                    {
                        label: '定位',
                        type: 7,
                    },
                    {
                        label: '省/市/区',
                        type: 8,
                    },
                    {
                        label: '性别',
                        type: 9,
                    },
                    {
                        label: '录音',
                        type: 12,
                    },
                    {
                        label: '视频',
                        type: 13,
                    },
                    {
                        label: '邮箱',
                        type: 14,
                    },
                    {
                        label: '学校',
                        type: 15,
                    },
                    {
                        label: '院系',
                        type: 16,
                    },
                    {
                        label: '工号',
                        type: 17,
                    },
                    {
                        label: '学号',
                        type: 18,
                    },
                    {
                        label: '公司',
                        type: 19,
                    },
                    {
                        label: '职位',
                        type: 20,
                    },
                    {
                        label: '部门',
                        type: 21,
                    },
                    {
                        label: '单位',
                        type: 22,
                    },
                ],
                // 报名字段
                listList: [{
                    signListName: '',
                    ifDel: 2,
                    addTime: '',
                    signField: [

                    ],
                }],
                // 格式验证
                validateArr: [
                    {
                        label: '不限格式',
                        value: 0
                    },
                    {
                        label: '仅中文',
                        value: 1
                    },
                    {
                        label: '仅英文',
                        value: 2
                    },
                    {
                        label: '仅数字',
                        value: 3
                    },
                    {
                        label: '英文+数字',
                        value: 4
                    },
                    {
                        label: '中文+数字',
                        value: 5
                    },
                    {
                        label: '中文+英文',
                        value: 6
                    },
                    {
                        label: '身份证号',
                        value: 7
                    },
                    {
                        label: '手机号',
                        value: 8
                    },
                    {
                        label: '座机号',
                        value: 9
                    },
                    {
                        label: '邮箱',
                        value: 10
                    },
                    {
                        label: '网址',
                        value: 11
                    },

                ],
                validateIndex: 0,
                commValue: '',
                commType: '',
                commIndex: '',
                timeSetList:[
                    {
                        type: 0,
                        value: '23:59:59',
                        checked: false
                    },
                    {
                        type: 1,
                        value: '2024-05',
                        checked: false
                    },
                    {
                        type: 2,
                        value: '2024-05-01',
                        checked: false
                    },
                    {
                        type: 3,
                        value: '2024-05-01 23:59:59',
                        checked: false
                    },
                ],
                areaSetList:[
                    {
                        type: 0,
                        value: '省',
                        checked: false
                    },
                    {
                        type: 1,
                        value: '省/市',
                        checked: false
                    },
                    {
                        type: 2,
                        value: '省/市/区',
                        checked: false
                    },
                    {
                        type: 3,
                        value: '省/市/区/详细地址',
                        checked: false
                    },
                ],
                storgeName: [],
                // ---------------------------------------------------------------------------------
                // 打卡内容
                formClock:{
                    clockFrequencyName:'每天', //打卡要求
                    clockRemindStatus:1, //打卡提醒开关 1 开 2关
                    remindTime:'10:00', //打卡提醒时间
                    beginTime:'00:00', //打卡开始时间
                    endTime:'23:59', //打卡结束时间
                    clockSupplyStatus:1, //补卡开关  1 开 2关
                    supplyTimes:'3', //补卡次数
                    clockLocationStatus:2,  //是否指定打卡地点 1 开 2关
                    clockRange: 1000, //打卡范围
                    clockFrequency:'0,1,2,3,4,5,6', //打卡要求 周、每天
                    // 新加的 有用的
                    clockLocationInfo:'',  //打卡地点信息
                    clockFrequencyType: '3', // 打卡频率类型！！！！！
                    clockMaxNum: 1, // 每周、每月打卡 最大次数
                    clockMaxSwitch: 2, // 每周、每月最大值开关
                    dayOfDate: '', // 每周、每月打卡截止—周几或几号，根据clockFrequencyType变化（周几 存的是 0、1、2、3、4、5、6   月：几号就是几，不变）
                    timeOfDay: '', // 每周、每月打卡截止—时分秒
                    multiPeriodSwitch: 2, // 多时段打卡开关
                    clockInTimeSlot: [
                        {
                            startTime: "00:00",
                            endTime: "23:59",
                            startAndEnd:['00:00','23:59'],
                            ifDel: 2, // 删除是1，未删除是2
                            randomDecimal: Date.now(),
                        },
                    ],
                    customDate: '', // 自定义日期 逗号拼接的
                },
                clockFrequencyName: '',
                markers:{},
                range:'1',
                address:'',
                clockRange: 1000,
                selectData:'每天',
                starttime: '00:00',
                endtime: '23:59',
                show:false,
                show2:false,
                color: '#1877FF',
                candidates: [
                    {name:'星期一',value:'0',checked:false},
                    {name:'星期二',value:'1',checked:false},
                    {name:'星期三',value:'2',checked:false},
                    {name:'星期四',value:'3',checked:false},
                    {name:'星期五',value:'4',checked:false},
                    {name:'星期六',value:'5',checked:false},
                    {name:'星期日',value:'6',checked:false}
                ],

                columns3:[["1000米以内","500米以内","200米以内","100米以内","50米以内"]],
                dakaColumnsArr: [
                    {
                        label:'1000米以内',
                        value: 1000,
                    },
                    {
                        label:'500米以内',
                        value: 500,
                    },
                    {
                        label:'200米以内',
                        value: 200,
                    },
                    {
                        label:'100米以内',
                        value: 100,
                    },
                ],
                city: '',
                current:0,
                selectFrequency: 3, // 频率类型 1=每月｜2=每周｜3=每天｜4=法定工作日｜5=法定节假日｜6=自定义周几打卡｜7=自定义日期打卡 | 8=一次性打卡
                lastDayOfMonth: '',
                weekChineseNumbers: ['一', '二', '三', '四', '五', '六', '日'],
                endMonthShow: false,
                endMonthArr: [
                    [],
                    [],
                    []
                ],
                endMonthArr1: [],
                endMonthArr2: [],
                endMonthArr3: [],
                endMonthValue: '',
                endWeekShow: false,
                endWeekArr: [
                    ['一', '二', '三', '四', '五', '六', '日'],
                    [],
                    []
                ],
                endWeekArr1: [
                    {
                        label: '周一',
                        value: 0,
                    },
                    {
                        label: '周二',
                        value: 1,
                    },
                    {
                        label: '周三',
                        value: 2,
                    },
                    {
                        label: '周四',
                        value: 3,
                    },
                    {
                        label: '周五',
                        value: 4,
                    },
                    {
                        label: '周六',
                        value: 5,
                    },
                    {
                        label: '周日',
                        value: 6,
                    }
                ],
                endWeekArr2: [],
                endWeekArr3: [],

                endWeekValue: '',
                modeRili: 'multiple',
                cusRilishow: false,
                customDateArr: [],
                checkboxValue1:[],
                // 打卡频率
                CheckInFrequency:[
                    {
                        label:'每月',
                        value: 1,
                    },
                    {
                        label:'每周',
                        value: 2,
                    },
                    {
                        label:'每天',
                        value: 3,
                    },
                    {
                        label:'法定工作日',
                        value: 4,
                    },
                    {
                        label:'法定节假日',
                        value: 5,
                    },
                    {
                        label:'自定义周几打卡',
                        value: 6,
                    },
                    {
                        label:'自定义日期打卡',
                        value: 7,
                    },
                    {
                        label:'一次性打卡',
                        value: 8,
                    },

                ],
                monthDay: 0,
                monthHour: 0,
                monthMinute: 0,
                weekDay: 1,
                weekHour: 1,
                weekMinute: 0,
                // 时间段
                timeSlotArr: ['00:00','23:59'],
                selectedFile: null,
                modulCommonIndex: 0,
                imageUrl: imageUrl,
                modularDelIds: [],
                // 查看活动类型
                selectType: 'add', //add
                delListInfo: [],
                activityId: '2',
                previewImgSrc: '', // 图片路径
                previewImgShow: false,
                isModular: false,
                addMapType: 1, // 2-模块 3-设置 1-打卡
                imageUploadLimit: 9,
                imgUpMoveSrc: 'http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png',
                imgDownMoveSrc: 'http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png',
                imgCancelSrc: 'http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png',

                // 高级设置
                shareStatus:'',
                commentStatus: 2,
                confirmWord:'参加接龙',
                select_type:"",
                activityType:0,
                isanonymous: 1,
                publisher: localStorage.getItem('jlnickname'),
                remindtime:'00:00',
                edit_publisher:'',
                columns1: [
                    ['不允许修改', '自定义修改时间', '接龙终止前']
                ],
                // 允许修改报名信息
                updateSignInfo: 3, // 是否允许修改 1、活动结束前可以  2、不可以取消 3、时间段内可以
                updateSignArr:[
                    {
                        label:'不允许修改',
                        value: 1,
                    },
                    {
                        label:'自定义修改时间',
                        value: 2,
                    },
                    {
                        label:'接龙终止前',
                        value: 3,
                    },

                ],
                columns1Show:false,
                columns2: [
                    ['不允许删除', '自定义删除时间', '接龙终止前']
                ],
                removeSignStatus: 4,
                cancellation: [
                    ['活动终止前', '不可取消', '自定义', '允许取消']
                ],
                cancellationArr: [
                    {
                        label:'活动终止前',
                        value: 1,
                    },
                    {
                        label:'不可取消',
                        value: 2,
                    },
                    {
                        label:'自定义',
                        value: 3,
                    },
                    {
                        label:'允许取消',
                        value: 4,
                    },

                ],
                columns2Show:false,
                anonymousparticipation: 0,

                submitcopy:'参加接龙',
                edit_submit_btn:'',
                edit_partin_btn:'',
                is_notice: 0,// 接龙通知
                is_forward: 0,//转发
                is_remind: 0,//接龙提醒
                cancelTitle:'接龙终止前',
                endregistrationtime:null,
                registrationtime:null,
                zdyTimes: false,
                zdyTimesEnd: false,
                zdyDelTimes:false,
                zdyDelTimesEnd:false,
                enddeletingtime:null,
                startdeletingtime:null,
                delSignTitle:'不允许删除',
                deleteregistration: 1,//是否允许删除报名信息1不允许 ，2自定义，3活动结束前
                is_signUpSet:false,//报名信息设置
                is_destination: 0,
                deliveryaddress:'',
                newSeniorSetInfo:{},
                informationswitch:false,//报名信息设置开关
                custombutton:false,//自定义按钮设置开关
                addresscolumns:[["2000米以内","1500米以内","1000米以内","500米以内","200米以内","100米以内"]],//打卡范围 数组
                addresscolumnsArr: [
                    {
                        label:'2000米以内',
                        value: 2000,
                    },
                    {
                        label:'1500米以内',
                        value: 1500,
                    },
                    {
                        label:'1000米以内',
                        value: 1000,
                    },
                    {
                        label:'500米以内',
                        value: 500,
                    },
                    {
                        label:'200米以内',
                        value: 200,
                    },
                    {
                        label:'100米以内',
                        value: 100,
                    },
                ],
                addressShow:false,
                clockRange1:'',//打卡范围
                checkinrange:'',//打卡范围
                specifyaddress:'',//
                ifcopy:false,//是否为复制活动
                thumbs_up: 1,//点赞
                is_comment: 1,//点评
                is_score: 1,//评分
                signInfoStatus: 1,//报名信息展示
                isContentFocus: true,
                isInputContentFocus: false,
                isFocus: false,
                modelContentFocus:false,
                modelContentbind:true,
                ishandleFocus:false,
                is_template: '0', // 1-模板 0-不是模板是活动
                valueId: null,//点击输入框索引
                valueId2:null,
                valueId3:null,
                valueId4:null,
                valueId5:null,
                valueId6:null,
                valueId7:null,
                valueId8:null,
                selected1:false,
                selected2:false,
                valueindex:null,
                valueindex1:null,
                selectedItem:null,
                copyStatus: 2, // 是否运行他们复制
                ctStartAndEndTime: '',
                deadline: '',
                deadCancelline: '',
                interdict_people_num: 0, // 人数限制

                seniorShow: false,
                openNameListDrawer: false,
                nameShowContent: '',
                people_num:0,
                importVisible: false,
                nameListDrawer: false,
                addNameListDrawer: false,
                customizeDrawer: false,
                clockInList: [], // 预存名单
                presetsList: [], // 名单管理列表
                clockShowPresetTitle: '',
                clockShowPresetContent: '',
                nameId: '',
                people_num2: '',
                copywriting :'',
                copyimage:'',
                copyShowWriting :'',
                copyShowImage:'',
                is_draft: 0, // 是否是草稿，0不是 1是草稿
                isrepeatClick: false, //防止连续点击创建
                showName: '列表接龙',
                baseurl: '',
                multipleActivitiesStatus: 1, //是否允许参加多项活动 1、允许参加多项活动  2、不允许参加多项活动
                routeParameter: '', // 链接参数
                pageType: '',
                imgInfo: [],
                isOtherPeople: '', // 1=创建者、2=管理员、3=普通用户
                loadingShow: {},
                activeBuildTitle: '通用接龙',
                musicLink: '',
                musicDialog: false,
                fileIframeShow: false,
                fileUrl: '',
            };
        },
        mounted() {
            var str11 = dayjs().format('DD/MM/YYYY');
            console.log(str11,'riqiaaaaa')
            // 页面参数
            this.routeParameter = this.$route.query;
            if (this.routeParameter.activityId){
                this.activityId = this.routeParameter.activityId;
            }
            if (this.routeParameter.selectType){
                this.selectType = this.routeParameter.selectType;
            }
            if (this.routeParameter.is_template){
                this.is_template = Number(this.routeParameter.is_template); // 1-模板 0-不是模板是活动
            }
            if (this.routeParameter.isType){
                this.isType= this.routeParameter.isType;
            }
            if (this.routeParameter.selectType){
                this.selectType = this.routeParameter.selectType;
            }

            this.pageType = this.routeParameter.pageType;
            this.ifcopy= this.routeParameter.copy;
            this.groupId= this.routeParameter.groupId;


            if (this.isType == 109){
                this.activeBuildTitle = '打卡接龙'
            }
            if (this.isType == 110){
                this.activeBuildTitle = '周期打卡'
            }
            if (this.isType == 106){
                this.activeBuildTitle = '通用接龙'
            }

            this.subAddress()
            this.lastDayOfMonth = this.getLastDayOfMonth()
            // 截止赋值
            var arr1 = [];
            for (var i = 1; i <= 31; i++){
                var str = i + '号';
                var _json = {
                    value: i,
                    label: str
                }
                arr1.push(_json);
            }
            this.endMonthArr1 = arr1;

            var arr2 = [];
            for (var j=0; j <= 23; j++){
                if (j < 10){
                    var timeJson1 = {
                        value: j,
                        label: '0'+j+'时'
                    }
                    arr2.push(timeJson1);
                } else {
                    var timeJson2 = {
                        value: j,
                        label: j+'时'
                    }
                    arr2.push(timeJson2);
                }
            }
            this.endMonthArr2 = arr2;
            this.endWeekArr2 = arr2;

            var arr3 = [];
            for (var g=0; g <= 59; g++){
                if (g < 10){
                    var timeJson3 = {
                        value: g,
                        label: '0'+g+'分'
                    }
                    arr3.push(timeJson3);
                } else {
                    var timeJson4 = {
                        value: g,
                        label: g+'分'
                    }
                    arr3.push(timeJson4);
                }
            }
            this.endMonthArr3 = arr3;
            this.endWeekArr3 = arr3;


            this.clockPresetsList()
            this.activityDetailFun()



        },
        methods: {
            openMusic(info){
                console.log(info,'信息啊')
                var link = info.fileUrl;
                this.musicLink = this.imageUrl + link;
                this.musicDialog = true;
            },

            submit(){
                if (this.isrepeatClick) {
                    return
                }
                this.isrepeatClick = true
                let that = this;
                //校验是否填写了接龙标题
                if (this.activityDetail.name == '' || this.activityDetail.name == null) {
                    this.$message('请填写接龙标题');
                    this.isrepeatClick = false
                    return false
                }
                if (this.selectType == "add") {
                    this.activityDetail.templateId = this.activityDetail.id
                    delete this.activityDetail.id
                }

                // 如果不是草稿
                if (this.is_draft == 0){
                    var isStop = false
                    this.listList.map((item, index) => {
                        if (isStop) {
                            this.isrepeatClick = false
                            return
                        }
                        if (item.signListName == '') {
                            isStop = true;
                            this.$message('请填写第' + (index + 1) + '个分组名称');
                        }
                        return
                    })
                    var isture = true;
                    if (this.isType == '109' || this.isType == '110' || this.isType == '104') {
                        var formClockObj = this.formClock;
                        console.log(formClockObj,'字段呢？？？')
                        if (formClockObj.clockRemindStatus == 1) {
                            if (formClockObj.remindTime == '') {
                                this.$message('打卡提醒时间不能为空！');
                                isture = false
                                this.isrepeatClick = false
                            }
                        }

                        if (formClockObj.clockLocationStatus == 1) {
                            if (formClockObj.address == '') {
                                this.$message('地点位置不能为空！')
                                isture = false
                                this.isrepeatClick = false
                            } else if (formClockObj.clockRange == '') {
                                this.$message('打卡范围不能为空！')
                                isture = false
                                this.isrepeatClick = false
                            }
                        }

                        // 判断是否选中日期
                        if (this.selectFrequency == 7){
                            if (this.customDateArr.length == 0){
                                this.$message('请选择日期！')
                                isture = false
                                this.isrepeatClick = false
                            }
                        }
                        // 自定义周几：是否选择周几
                        if (this.selectFrequency == 6){
                            if (formClockObj.clockFrequency == ''){
                                this.$message('请选择周几打卡！')
                                isture = false
                                this.isrepeatClick = false
                            }
                        }
                    }

                    if (this.activityDetail.removeSignStatus == 3) {
                        if (this.activityDetail.removeSignEndTime == null) {
                            this.$message('自定义开始日期不能为空')
                            isture = false
                        } else if (this.activityDetail.removeSignBeginTime == null) {
                            this.$message('自定义结束日期不能为空')
                            isture = false
                        }
                    }
                    if (isture == false) {
                        this.isrepeatClick = false
                        return
                    }
                    // 表单项验证
                    if (this.listList[this.indexIdx]) {
                        this.listList[this.indexIdx].signField.map((item, index) => {
                            console.log(item, "7777778");
                            if (isStop) {
                                this.isrepeatClick = false
                                return
                            }

                            console.log(item.content);

                            if (item.name == "") {
                                isStop = true;
                                this.$message('请填写第' + (index + 1) + '个报名信息列')
                                this.isrepeatClick = false
                            }
                            if (item.type == 1) {
                                if (item.fieldName == '') {
                                    isStop = true;
                                    this.$message('请填写填空标题')
                                    this.isrepeatClick = false
                                }
                            }
                            if (item.type == 9) {
                                if (item.fieldName == '') {
                                    isStop = true;
                                    this.$message('请填写多行输入标题')
                                    this.isrepeatClick = false
                                }
                            }
                            if (item.type == 2) {
                                item.content.map((itm, idx) => {
                                    console.log("是否先进入了这里2");
                                    if (itm.value == "") {
                                        isStop = true;
                                        this.$message('请填写单选选项内容')
                                        this.isrepeatClick = false
                                    }
                                })
                                if (item.fieldName == '') {
                                    console.log("是否先进入了这里1");
                                    isStop = true;
                                    this.$message('请填写单选标题')
                                    this.isrepeatClick = false
                                }
                            }
                            if (item.type == 4) {
                                item.content.map((itm, idx) => {
                                    if (itm.value == "") {
                                        isStop = true;
                                        this.$message('请填写多选选项内容')
                                        this.isrepeatClick = false
                                    }
                                })
                                if (item.fieldName == '') {
                                    isStop = true;
                                    this.$message('请填写多选标题')
                                    this.isrepeatClick = false
                                }
                            }

                        })
                    }
                    if (this.activityDetail.removeSignStatus == 3) {
                        if (this.activityDetail.removeSignBeginTime && this.activityDetail.removeSignEndTime) {
                            if (new Date(this.activityDetail.removeSignBeginTime).getTime() > new Date(this.activityDetail
                                .removeSignEndTime).getTime()) {
                                isStop = true;
                                this.$message('结束日期不能小于开始日期')
                                this.isrepeatClick = false
                            }
                        }
                    }
                    if (this.activityDetail.beginTime && this.activityDetail.endTime) {
                        if (new Date(this.activityDetail.beginTime).getTime() > new Date(this.activityDetail.endTime).getTime()) {
                            isStop = true;
                            this.$message('结束时间不能小于开始时间')
                            this.isrepeatClick = false
                        }
                    }

                    if (isStop) {
                        this.isrepeatClick = false
                        return
                    }

                }

                this.activityDetail.signShowType = this.showName == "列表接龙" ? 1 : 2;

                that.activityDetail.shareTitle = ''
                that.activityDetail.shareCotent = ''
                that.activityDetail.shareBtnWord = ''
                that.activityDetail.shareBack = ''
                that.activityDetail.shareLogo = ''
                that.activityDetail.shareImg = ''

                // 高级设置
                that.activityDetail.shareStatus = this.shareStatus;
                that.activityDetail.commentStatus = this.commentStatus;
                that.activityDetail.updateSignInfo = this.updateSignInfo;
                that.activityDetail.confirmWord = this.confirmWord
                this.activityDetail.copyStatus = this.copyStatus


                // 新加的 2024/8/1
                that.activityDetail.isanonymous =  this.isanonymous;
                that.activityDetail.publisher = localStorage.getItem("jlnickname") //发布人姓名
                that.activityDetail.is_notice = this.is_notice; //接龙情况通知开关

                that.activityDetail.copywriting = this.copywriting//自定义文案
                that.activityDetail.copyimage = this.copyimage//自定义图片

                that.activityDetail.is_destination = this.is_destination;//指定接龙位置
                that.activityDetail.deliveryaddress = this.deliveryaddress;//指定接龙位置坐标

                that.activityDetail.submitcopy  = this.isType=='109'|| this.submitcopy?this.submitcopy:'提交'//报名页面提交按钮文案
                that.activityDetail.registrationtime  = this.registrationtime;//修改报名信息开始时间
                that.activityDetail.endregistrationtime  = this.endregistrationtime;//修改报名信息结束时间
                that.activityDetail.custombutton  = 1 //uni.getStorageSync('custombutton')//自定义按钮标题，总开关
                that.activityDetail.checkinrange  = this.checkinrange; //打卡范围
                that.activityDetail.anonymousparticipation = this.anonymousparticipation; //匿名参与
                that.activityDetail.specifyaddress = this.specifyaddress;

                that.activityDetail.deleteregistration = this.deleteregistration; //修改报名信息
                that.activityDetail.startdeletingtime  = this.startdeletingtime; //删除报名信息开始时间
                that.activityDetail.enddeletingtime  = this.enddeletingtime; //修改报名信息结束时间

                that.activityDetail.is_forward = this.is_forward;
                that.activityDetail.thumbs_up = this.thumbs_up;
                that.activityDetail.is_comment = this.is_comment;
                that.activityDetail.is_score = this.is_score;
                that.activityDetail.musicId = ''
                that.activityDetail.musicName = '';

                let time = new Date()
                let nowTime = this.timestampToTime(time)
                that.activityDetail.beginTime = that.activityDetail.beginTime ? that.activityDetail.beginTime : nowTime;

                // 转格式
                that.modular1 = JSON.parse(JSON.stringify(this.modularList))
                that.modular1.map((item, index) => {
                    console.log(item, "itemitem");
                    if (this.is_draft == 0){
                        if (isStop) {
                            this.isrepeatClick = false
                            return
                        }
                        if (item.type == 1 && item.info.value == "") {
                            isStop = true;
                            this.$message('请填写接龙标题');
                        } else if (item.type == 2 && item.info.value == "") {
                            isStop = true;
                            this.$message('请填写接龙内容');
                        } else if (item.type == 3 && item.info.imgarr.length == 0) {
                            isStop = true;
                            this.$message('请上传图片');
                        } else if (item.type == 9 && item.info.imgarr.length == 0) {
                            isStop = true;
                            this.$message('请上传大图模块图片');
                        } else if (item.type == 4 && (item.info.latitude == "" || item.info.longitude == "")) {
                            isStop = true;
                            this.$message('请选择地址');
                        } else if (item.type == 5 && item.info.fileUrl == "") {
                            isStop = true;
                            this.$message('请上传文件');
                        } else if (item.type == 6 && item.info.filesArr.length == 0) {
                            isStop = true;
                            this.$message('请上传音频');
                        } else if (item.type == 7 && item.info.fileUrl == "") {
                            isStop = true;
                            this.$message('请上传视频');
                        }
                        if (item.type == 8 && item.info.link == ''){
                            isStop = true;
                            this.$message('请填写公众号链接');
                        }
                        if (item.type == 9 && (item.info.videoId == ''|| item.info.videoNumberID == '')){
                            isStop = true;
                            this.$message('请填写视频号信息');
                        }
                        if (item.type == 12 && (item.info.appid == ''|| item.info.pagePath == '')){
                            isStop = true;
                            this.$message('请填写其他小程序信息');
                        }
                        if (item.type == 11 && item.copy_region_conten == ''){
                            isStop = true;
                            this.$message('请填写一键复制区信息');
                        }
                    }
                    item.info = JSON.stringify(item.info)
                })

                // 如果不是草稿
                if (this.is_draft == 0){
                    if ( that.activityDetail.is_destination == 1) {
                        if (that.activityDetail.specifyaddress == '') {
                            this.$message('请选择指定参与地点');
                            isStop = true;
                        } else if (that.activityDetail.checkinrange == '') {
                            this.$message('参与范围不能为空');
                            isStop = true;
                        }
                    }
                    if (isStop) {
                        this.isrepeatClick = false
                        return
                    }
                }

                that.submitFn()

            },
            submitFn(){
                console.log("去创建")
                let appliction = []; //报名填表项
                let fmClockIn = {};
                // let longitudeT = '';
                // let latitudeT = '';
                let clockInList;
                let projectDelIds;
                if (this.isType == '106' || this.isType == '107') {
                    // appliction = this.$refs.registration.appliction
                    clockInList = this.storgeName.join(',')
                    // projectDelIds = this.$refs.registration.projectDelIds
                }
                if (this.isType == '109' || this.isType == '110' || this.isType == '104') {
                    fmClockIn = this.formClock;
                    // longitudeT = this.locationObj.longitude.toString();
                    // latitudeT = this.locationObj.latitude.toString();
                    clockInList = this.storgeName.join(',');
                }
                this.activityDetail.coverImg = this.baseurl;

                let signFieldClone = ''
                signFieldClone = JSON.parse(JSON.stringify(this.listList))

                signFieldClone[this.indexIdx].signField.push(...this.delListInfo);

                signFieldClone.map((item, index) => {
                    console.log(item.content, "4444444444444");
                    item.signField.map(itm => {
                        if (itm.content != '' && itm.content != null) {
                            console.log("789789");
                            return itm.content = JSON.stringify(itm.content)
                        } else {
                            console.log('4444');
                            return {}
                        }
                    })

                })
                console.log(signFieldClone,'signFieldClone上移')

                // loading  loadingShow
                this.loadingShow = this.$loading({
                    lock: true,
                    text: '保存中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.activityDetail.activityType = this.isType;
                // 创建/编辑活动
                updateActivity({
                    ...this.activityDetail,
                    "signList": signFieldClone,
                    "modular": this.modular1,
                    "modularDelIds": this.modularDelIds,
                    //"removeSignStatus":this.removeSignStatus, //新修改
                    "multipleActivitiesStatus": this.multipleActivitiesStatus,
                    "projectVos": appliction, ///报名填表项
                    "fmClockIn": fmClockIn, //打卡
                    "activityBigType": '1', //接龙大类型 接龙1 通知2
                    "activityType": this.isType,
                    "clockInList": clockInList, //预存名单列表
                    "projectDelIds": projectDelIds,
                    "groupId": this.groupId ? this.groupId : '',
                    interdict_people_num: this.interdict_people_num,
                    is_draft: this.is_draft,
                    // "longitudeT":longitudeT, //当前位置经纬度
                    // "latitudeT":latitudeT,//当前位置经纬度
                }).then((res) => {
                    console.log(res)
                    if (res.status == 1){
                        if (this.selectType == 'update'){
                            console.log('走编辑哦！！！')
                            this._updateSignList(signFieldClone,res.data)
                        } else {
                            this.loadingShow.close();
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            // 如果是草稿 跳到index
                            if (this.is_draft == 1){
                                router.push("/home");
                                return false;
                            }
                            router.replace("/releaseNotes");
                        }
                    } else {
                        this.$message(res.msg);
                        this.isrepeatClick = false;
                    }
                })
            },
            // 编辑填表项
            async _updateSignList(signList,id){
                let result = await updateSignList({
                    signList: signList,
                    id: this.activityId,
                    userId: this.activityDetail.userId
                })
                if (result.status == 1){
                    this.loadingShow.close();
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    // 如果是草稿 跳到index
                    if (this.is_draft == 1){
                        router.push("/home");
                        return false;
                    }
                    router.replace("/releaseNotes");
                } else {
                    this.loadingShow.close();
                    this.$message(result.msg);
                    this.isrepeatClick = false
                }
            },
            // 活动详情
            activityDetailFun(){
                console.log('活动详情接龙-------')
                var that = this;
                activityDetail({
                    activityId: this.activityId,
                    selectType: this.selectType,
                    is_template: this.is_template,
                }).then(res => {
                    console.log(res.data,'活动数据是啥啊')
                    this.activityDetail = res.data.activityDetail;
                    this.activityDetail.activityId = this.activityId;
                    this.activityDetail.id = this.activityId;
                    if (res.data.activityDetail.beginTime){
                        this.actStartAndEndTime = [res.data.activityDetail.beginTime,res.data.activityDetail.endTime]
                    }
                    if (this.selectType == 'add'){
                        var startTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                        var endTime = dayjs(startTime).add(1,'month').format('YYYY-MM-DD HH:mm:ss')
                        this.activityDetail.beginTime = startTime;
                        this.activityDetail.endTime = endTime;
                        this.actStartAndEndTime = [startTime,endTime]
                    }

                    res.data.modular.map((item) => {
                        console.log(item);
                        if (item.type == 4){
                            var obj = {
                                lng: 116.403963,
                                lat: 39.915119
                            }
                            item.location = obj;
                        }
                        item.info = item.info.replace(/\n/g, "\\n")
                        item.info = item.info.replace(/↵/g, " ")
                        var info = JSON.parse(item.info)
                        if (that.selectType == "add") {
                            delete item.id
                            delete item.activityId
                        }
                        item.info = info
                    })
                    this.modularList = res.data.modular;

                    if (res.data.modular.length <= 1){
                        var textarea = document.querySelector('.el-textarea__inner');
                        textarea.addEventListener('input', (e) => {
                            textarea.style.height = '100px';
                            textarea.style.height = e.target.scrollHeight + 'px';
                        });
                    }

                    // 填表项
                    let signFieldCloneS = ''
                    signFieldCloneS = JSON.parse(JSON.stringify(res.data.signList))
                    signFieldCloneS.map((itm) => {
                        itm.signField.map((item) => {
                            item.isUniqueCheckChecked = item.isUniqueCheck == 1?true:false;
                            item.isRequiredChecked = item.isRequired == 1?true:false;
                            item.isOpenChecked = item.isOpen == 1?true:false;
                            if (item.content != '' && item.content != null) {
                                return item.content = JSON.parse(item.content)
                            } else {
                                item['num'] = 3;
                                return {}
                            }
                        })
                    })
                    this.$nextTick(()=>{
                        this.listList = signFieldCloneS
                    })
                    console.log(signFieldCloneS,'详情接口---signFieldCloneS')
                    // 接龙名单
                    if (res.data.clockNameList) {
                        res.data.clockNameList.forEach(item => {
                            if (res.data.activityDetail.isTemplate == 1) {
                                this.storgeName.push(item.clockName)
                            } else {
                                this.storgeName.push(item.clockName)
                                this.storgeName.map((res, index) => {
                                    if (res == "") {
                                        this.storgeName.splice(index, 1)
                                    }
                                })
                            }
                        })
                    }

                    // 打卡接龙
                    if (res.data.clockInfo) {
                        this.formClock = res.data.clockInfo;
                        this.selectData = res.data.clockInfo.clockFrequencyName;
                        this.clockFrequencyName = res.data.clockInfo.clockFrequencyName;
                        //自定义周几赋值
                        res.data.clockInfo.clockFrequency.split(',').forEach(item => {
                            this.candidates.forEach(e => {
                                if (e.value == item) {
                                    e.checked = 'true';
                                    this.candidateList.push(e.value)
                                }
                            })
                        })
                        // 打卡地点
                        if (res.data.clockInfo.clockLocationInfo != "") {
                            this.formClock.address = JSON.parse(res.data.clockInfo.clockLocationInfo).address
                            this.formClock.clockRange = res.data.clockInfo.clockRange;
                        }
                        this.selectFrequency = res.data.clockInfo.clockFrequencyType;
                        this.formClock.clockFrequencyType = res.data.clockInfo.clockFrequencyType;

                        // 处理时间段
                        if (res.data.clockInfo.clockInTimeSlot && res.data.clockInfo.clockInTimeSlot.length){
                            console.log('没进来吗')
                            res.data.clockInfo.clockInTimeSlot.forEach((item,index) => {
                                item.ifDel = 2;
                                // item.startAndEnd = [item.startTime.toString(),item.endTime.toString()]
                                let a = [item.startTime.toString(),item.endTime.toString()]
                                this.$set(item, 'startAndEnd', a);
                            })
                            this.formClock.clockInTimeSlot = res.data.clockInfo.clockInTimeSlot;

                            if (res.data.clockInfo.clockInTimeSlot.length > 1){
                                this.formClock.multiPeriodSwitch = 1;
                            }

                        }
                        this.$forceUpdate()

                        // 日期选择
                        this.formClock.customDate = res.data.clockInfo.customDate;
                        this.customDateArr = res.data.clockInfo.customDate.split(',');
                        // 截止日期
                        this.formClock.dayOfDate = res.data.clockInfo.dayOfDate;
                        this.formClock.timeOfDay = res.data.clockInfo.timeOfDay;
                        if (this.selectFrequency == 1){
                            this.monthDay = res.data.clockInfo.dayOfDate;
                        }
                        if (this.formClock.timeOfDay){
                            var str1 = this.formClock.timeOfDay.substring(0,2)
                            this.endMonthArr2.forEach( (item,index) => {
                                if (item.value == str1){
                                    this.monthHour = index
                                }
                            })
                        }
                        if (this.formClock.timeOfDay){
                            var str2 = this.formClock.timeOfDay.substring(3)
                            this.endMonthArr3.forEach( (item,index) => {
                                if (item.value == str2){
                                    this.monthMinute = index
                                }
                            })
                        }

                        this.formClock.clockFrequency = res.data.clockInfo.clockFrequency;
                        this.formClock.checkboxValue1 = res.data.clockInfo.clockFrequency.split(',')

                        // 打卡最大次数
                        if (res.data.clockInfo.clockFrequencyType == 1 || res.data.clockInfo.clockFrequencyType == 2){
                            this.formClock.clockMaxNum = res.data.clockInfo.clockMaxNum;
                            if (res.data.clockInfo.clockMaxNum > 1){
                                this.formClock.clockMaxSwitch = 1;
                            } else {
                                this.formClock.clockMaxSwitch = 2;
                            }
                            this.$forceUpdate()
                        }
                        this.interdict_people_num = res.data.activityDetail.interdict_people_num;
                        this.is_draft = res.data.activityDetail.is_draft;

                    }
                    console.log(this.formClock,'打卡信息--')

                    // 高级设置
                    var detailInfo = res.data.activityDetail;
                    this.publisher = detailInfo.publisher?detailInfo.publisher:localStorage.getItem("jlnickname");
                    this.copyStatus = detailInfo.copyStatus;
                    if(this.isType=='109'||this.isType=='110'||this.type == '104'){
                        this.confirmWord ='参与打卡'
                    }else{
                        this.confirmWord = detailInfo.confirmWord;
                    }
                    this.is_forward = detailInfo.is_forward;
                    this.updateSignInfo = this.selectType=='update'?detailInfo.updateSignInfo:3;
                    this.activityDetail.removeSignStatus = this.selectType=='update'?detailInfo.removeSignStatus:4;
                    this.copywriting = detailInfo.copywriting;
                    this.copyimage = detailInfo.copyimage;
                    this.is_destination =  detailInfo.is_destination;
                    this.deliveryaddress = detailInfo.deliveryaddress;
                    if(this.isType=='109'||this.isType=='110' ||this.isType=='104'){
                        this.submitcopy ='立即打卡'
                    }else{
                        this.submitcopy = detailInfo.submitcopy
                    }
                    this.commentStatus = this.selectType=='update'? detailInfo.commentStatus:2;
                    this.signInfoStatus = detailInfo.signInfoStatus;
                    this.custombutton = detailInfo.custombutton;
                    this.anonymousparticipation = detailInfo.anonymousparticipation;
                    this.deleteregistration = detailInfo.deleteregistration;
                    this.checkinrange = detailInfo.checkinrange
                    this.specifyaddress = detailInfo.specifyaddress;
                    if(this.isType=='109'||this.isType=='110' ||this.isType == '104'){
                        this.confirmWord ='参与打卡'
                    }else{
                        this.confirmWord = detailInfo.confirmWord
                    }
                    this.startdeletingtime = detailInfo.startdeletingtime
                    this.enddeletingtime =  detailInfo.enddeletingtime
                    // deadline -修改报名信息时间范围
                    this.activityDetail.registrationtime = detailInfo.registrationtime;
                    this.activityDetail.endregistrationtime = detailInfo.endregistrationtime;
                    if (detailInfo.registrationtime){
                        this.deadline = [detailInfo.registrationtime,detailInfo.endregistrationtime]
                    }

                    if (detailInfo.removeSignBeginTime){
                        this.deadCancelline = [detailInfo.removeSignBeginTime,detailInfo.removeSignEndTime]
                    }

                    this.thumbs_up = detailInfo.thumbs_up;
                    this.is_comment = detailInfo.is_comment;
                    this.is_score = detailInfo.is_score;
                    if(this.selectType=='update' || this.ifcopy){
                        this.isanonymous = detailInfo.isanonymous
                    }
                    this.multipleActivitiesStatus = detailInfo.multipleActivitiesStatus //是否允许参加多个活动

                    this.showName = detailInfo.signShowType == 1 ? "列表接龙" : "网格接龙"

                    this.coverimgClone()
                    this.clockPresetsList()
                    if (this.selectType != 'add'){
                        this._getSignList()
                        this._getProjectManageAdmin()
                    }
                })


            },
            // 获取封面图片接口
            async coverimgClone() {
                const {
                    data,
                    msg,
                    status
                } = await getCoverimgTemplate()
                console.log(data);
                this.imgInfo = data
                this.baseurl = this.baseurl ? this.baseurl : data[0].coverImg
                this.imgInfo.map((item, index) => {
                    item.imgShowLv = false
                })
                this.imgInfo[0].imgShowLv = true;
                if (this.activityId != 2) {
                    this.baseurl = this.activityDetail.coverImg
                } else {
                    this.baseurl ? this.baseurl : this.imgInfo[0].path
                }
            },
            // 获取报名人数
            async _getSignList() {
                const {
                    data,
                    msg,
                    status
                } = await getSignList({
                    "activityId": this.activityDetail.id,
                    "signShowType": this.activityDetail.signShowType,
                })

                if (status == 1) {
                    if (data.pageInfo.list && data.pageInfo.list.length>0){
                        console.log(data.pageInfo.list,'报名列表---')
                        this.commonDisabled = true;
                        console.log(this.commonDisabled,'值呢？？？')
                    } else {
                        console.log(data,'无人报名吗')
                    }
                }
            },
            // 判断身份
            async _getProjectManageAdmin(){
                let result = await getProjectManageAdmin({
                    activityId: this.activityId
                })
                if(result.status != 901){
                    this.isOtherPeople = result.data;
                    if (this.activityId == 2){
                        this.isOtherPeople = 1;
                    }
                }

            },

            // 编辑名单
            nameLists(id){
                console.log(id)
                this.nameId = id;
                this.clockPresetsDetail()
            },
            async clockPresetsDetail(){
                const {
                    data,
                    status
                } = await clockPresetsDetail({
                    "id": this.nameId
                })
                if(status == 1){
                    this.clockShowPresetTitle = data.clockPresetTitle
                    this.clockShowPresetContent = data.clockPresetContent
                    this.handlerBlur()
                    this.addNameListDrawer = true;
                }
            },
            FocusInterdictPeople(){
                if(this.interdict_people_num==0){
                    this.interdict_people_num=''
                }
            },
            BlurInterdictPeople(e){
                if(this.interdict_people_num==0||this.interdict_people_num==''){
                    this.interdict_people_num=0
                }

            },
            oninputFun(e){
                // 正则表达式，匹配正整数和0
                const regex = /^(?:0|[1-9]\d*)$/;
                // 判断输入值是否匹配正则表达式
                if (!regex.test(e)) {
                    // 如果不匹配，则将输入值重置为上一个合法的值
                    e = e.replace(regex, '');
                }
                console.log(e)
            },
            cannotEdit(){
                this.$message({
                    message: '当前活动已经有人报名，不能修改',
                    type: 'warning'
                });
            },
            handlerBlur(){
                var reg = /([\n\r])+/g
                this.clockShowPresetContent = this.clockShowPresetContent.trim().replace(reg,'\n')
                let arr = this.clockShowPresetContent.split(/[\n\s+,，；;]/g);
                arr = arr.filter(item => item !="")
                // this.people_num = arr.length
                this.people_num2 = arr.length
            },

            presetsDel(id){
                console.log(id)
                this.nameId = id;
                this.$confirm('是否确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.confirmDelete();

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            EchoList(info){
                this.nameShowContent = info.clockPresetContent;
                this.nameListDrawer = false;
            },
            // 删除名单
            async confirmDelete(){
                const {
                    msg,
                    status
                } = await clockPresetsDel({
                    "id": this.nameId
                })
                if(status == 1){
                    this.$message({
                        type: 'success',
                        message: msg
                    });
                    this.clockPresetsList()
                } else {
                    this.$message(msg);
                }
            },
            // 获取名单管理列表
            clockPresetsList(){
                clockPresetsList({
                    pageNum: 1,
                    pageSize: 10
                }).then(res => {
                    this.presetsList = res.data.list
                })

            },

            // -添加模块
            addModule(num){
                // 自定义内容1
                if (num == 0) {
                    let kk = {
                        "type": 1,
                        "isCloneTf": 1,
                        info: {
                            "value": "",
                            "title":""
                        }
                    }
                    this.modularList.push(kk)
                }
                // 小图
                if (num == 2) {
                    var bb = {
                        "type": 3,
                        "isCloneTf": 1,
                        info: {
                            "value": "图片",
                            "imgarr": []
                        }
                    }
                    this.modularList.push(bb)
                }
                // 位置
                if (num == 3) {
                    var cc = {
                        "type": 4,
                        "isCloneTf": 1,
                        info: {
                            "value": "地址",
                            "name": "",
                            "address": "",
                            "latitude": "",
                            "longitude": ""
                        },
                        location:{
                            lng: 116.403963,
                            lat: 39.915119
                        }
                    }
                    this.modularList.push(cc)
                }
                // 文件
                if (num == 4) {
                    var dd = {
                        "type": 5,
                        "isCloneTf": 1,
                        info: {
                            "value": "文件",
                            filesArr:[]
                        }
                    }
                    this.modularList.push(dd)
                }
                // 音频
                if (num == 5) {
                    var ee = {
                        "type": 6,
                        "isCloneTf": 1,
                        info: {
                            "value": "音频",
                            "name": "",
                            "size": "",
                            "lastName": "",
                            "fileUrl": "",
                            filesArr:[]
                        }
                    }
                    this.modularList.push(ee)
                }
                // 视频
                if (num == 6) {
                    var ff = {
                        "type": 7,
                        "isCloneTf": 1,
                        info: {
                            "value": "视频",
                            "name": "",
                            "size": "",
                            "lastName": "",
                            "fileUrl": ""
                        }
                    }
                    this.modularList.push(ff)
                }
                // 大图
                if (num == 7) {
                    var gg = {
                        "type": 9,
                        "isCloneTf": 1,
                        info: {
                            "value": "大图",
                            imgarr: []
                        }
                    }
                    this.modularList.push(gg)
                }
                // 一键复制
                if (num == 11){
                    var ggg = {
                        "type": 13,
                        "isCloneTf": 1,
                        info: {
                            "value": "一键复制区",
                            "copy_region_conten":  '',
                        }
                    }
                    this.modularList.push(ggg)
                }

                this.$forceUpdate()
            },

            // 添加表单项 姓名/手机号 等
            addForm(item,index) {
                console.log(item,index);
                if (item == 0) {
                    var aa = {
                        "type": 1,
                        "fieldName": "姓名",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        "num": 3,
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「姓名」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(aa)
                    this.$forceUpdate()
                } else if (item == 1) {
                    var cc = {
                        "type": 11,
                        "fieldName": "手机号",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「手机号」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(cc)
                    this.$forceUpdate()
                } else if (item == 2) {
                    var dd = {
                        "type": 13,
                        "fieldName": "数量",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        randomDecimal: Date.now()
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「数量」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(dd)
                    this.$forceUpdate()
                } else if (item == 3) {
                    var ee = {
                        "type": 3,
                        "fieldName": "日期",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0, //
                        timeSelectType: 3, // 默认第4个
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「日期」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(ee)
                    this.$forceUpdate()
                } else if (item == 4) {
                    var ff = {
                        "type": 5,
                        "fieldName": "请上传图片",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        imageUploadLimit: 9,
                        randomDecimal: Date.now()
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「图片」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(ff)
                    this.$forceUpdate()
                } else if (item == 5) {
                    var gg = {
                        "type": 10,
                        "fieldName": "身份证",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「身份证」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(gg)
                    this.$forceUpdate()
                } else if (item == 6) {
                    var hh = {
                        "type": 15,
                        "fieldName": "时间",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「时间」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(hh)
                    this.$forceUpdate()
                } else if (item == 7) {
                    console.log("jinlail");
                    var qq = {
                        "type": 12,
                        "fieldName": "你所在的位置",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「位置」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(qq)
                    this.$forceUpdate()
                } else if (item == 8) {
                    var ww = {
                        "type": 8,
                        "fieldName": "请选择省市区",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0, // 默认第3个
                        areaSelectType: 2,
                        provinceCityDistrictAddress: '',
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「省市区」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(ww)
                    this.$forceUpdate()
                } else if (item == 9) {
                    var rr = {
                        "type": 2,
                        "fieldName": "性别",
                        "isRequired": 1,
                        "isOpen": 1,
                        "num": 2,
                        "content": [
                            {
                                value: "男",
                            },
                            {
                                value: '女'
                            }
                        ],
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「性别」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(rr)
                    this.$forceUpdate()
                } else if (item == 10) {
                    var tt = {
                        "type": 14,
                        "fieldName": "请上传健康码",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        "num": 7,
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,

                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「健康码」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(tt)
                    this.$forceUpdate()
                } else if (item == 11) {
                    var yy = {
                        "type": 14,
                        "fieldName": "请上传行程码",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        "num": 8,
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                    }
                    this.listList[this.indexIdx].signField.push(yy)
                    this.$forceUpdate()
                } else if (item == 12) {
                    var uu = {
                        "type": 7,
                        "fieldName": "请填写录音标题",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「录音」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(uu)
                    this.$forceUpdate()
                } else if (item == 13) {
                    var sp = {
                        "type": 6,
                        "fieldName": "请填写视频标题",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「视频」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(sp)
                    this.$forceUpdate()
                } else if (item == 14) {
                    var yxx = {
                        "type": 19, // bdbdbd 新版本加入的
                        "fieldName": "邮箱",
                        "placeholder": "邮箱",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「邮箱」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(yxx)
                    this.$forceUpdate()
                } else if (item == 15) {
                    var xx = {
                        "type": 21, // bdbdbd 新版本加入的
                        "fieldName": "学校",
                        "placeholder": "学校",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「学校」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(xx)
                    this.$forceUpdate()
                } else if (item == 16) {
                    var yx = {
                        "type": 22, // bdbdbd 新版本加入的
                        "fieldName": "院系",
                        "placeholder": "院系",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「院系」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(yx)
                    this.$forceUpdate()
                } else if (item == 17) {
                    var gh = {
                        "type": 23, // bdbdbd 新版本加入的
                        "fieldName": "工号",
                        "placeholder": "工号",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「工号」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(gh)
                    this.$forceUpdate()
                } else if (item == 18) {
                    var xh = {
                        "type": 24, // bdbdbd 新版本加入的
                        "fieldName": "学号",
                        "placeholder": "学号",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「学号」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(xh)
                    this.$forceUpdate()
                } else if (item == 19) {
                    var gs = {
                        "type": 25, // bdbdbd 新版本加入的
                        "fieldName": "公司",
                        "placeholder": "公司",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「公司」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(gs)
                    this.$forceUpdate()
                } else if (item == 20) {
                    var zw = {
                        "type": 26, // bdbdbd 新版本加入的
                        "fieldName": "职位",
                        "placeholder": "职位",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「职位」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(zw)
                    this.$forceUpdate()
                } else if (item == 21) {
                    var bm = {
                        "type": 27, // bdbdbd 新版本加入的
                        "fieldName": "部门",
                        "placeholder": "部门",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「部门」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(bm)
                    this.$forceUpdate()
                } else if (item == 22) {
                    var dw = {
                        "type": 28, // bdbdbd 新版本加入的
                        "fieldName": "单位",
                        "placeholder": "单位",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「单位」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(dw)
                    this.$forceUpdate()
                }
                // 水印拍照
                if (item == 23) {
                    var sy = {
                        "type": 20, // bdbdbd 新版本加入的
                        "fieldName": "水印拍照",
                        "placeholder": "水印拍照",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        watermarkImageUrl: '',
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「水印」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(sy)
                    this.$forceUpdate()
                }
                // 单行文本
                if (item == -1) {
                    var pp = {
                        "type": 1,
                        "fieldName": "",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        "num": 4, // 单行文本
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「单行文本」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(pp)
                    this.$forceUpdate()
                }
                // 单选
                if (item == -2) {
                    let pp = {
                        "type": 2,
                        "fieldName": "单选",
                        "isRequired": 1,
                        "isOpen": 1,
                        "num": 1,
                        "content": [{
                            value: '',
                            randomDecimal: Date.now()
                        }],
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「单选」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(pp)
                    this.$forceUpdate()
                }
                // 多选
                if (item == -3) {
                    var ll = {
                        "type": 4,
                        "fieldName": "多选",
                        "isRequired": 1,
                        "isOpen": 1,
                        "isChoose":2,
                        "isChooseNum":0,
                        "content": [{
                            value: '',
                            randomDecimal: Date.now()
                        }],
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「多选」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(ll)
                    console.log(this.listList[this.indexIdx].signField,'数据呢。。。。。。')
                    this.$forceUpdate()
                }
                // 多行文本
                if (item == -4) {
                    var hh2 = {
                        "type": 9,
                        "fieldName": "",
                        "isRequired": 1,
                        "isOpen": 1,
                        "content": "",
                        isUniqueCheck: 0, //是否唯一校验 0=否 1=是
                        isFormatCheck: 0,
                        isRequiredChecked: true,
                        isOpenChecked: true,
                        isUniqueCheckChecked: false,
                    }
                    this.$message({
                        showClose: true,
                        message: '已经添加了「多行文本」',
                        type: 'success'
                    });
                    this.listList[this.indexIdx].signField.push(hh2)
                    this.$forceUpdate()
                }
            },

            // 打开预存名单列表
            openNamelistFun(){
                this.nameListDrawer = true;
            },
            // 添加接龙名单
            sureAddNameList(){
                var reg = /([\n\r])+/g
                this.nameShowContent = this.nameShowContent.trim().replace(reg,'\n')
                let arr = this.nameShowContent.split(/[\n\s+,，；;]/g);
                arr = arr.filter(item => item !="")
                console.log(arr)
                if(arr.length>100){
                    this.$message('名单人数不能超过100');
                    return
                }
                this.clockInList = arr.join(',');
                this.storgeName = arr;
                this.openNameListDrawer = false;
            },
            importFiles(event){
                var that = this;
                this.selectedFile = event.target.files[0];
                let lastArr = this.selectedFile.name.split('.')
                if (lastArr[1] != "xls" && lastArr[1] != "xlsx") {
                    this.$message('文件格式不正确');
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var parm = {
                    file: this.selectedFile,
                    type: 'modularFile'
                }
                axios.post(serverConfig.baseURL+'/file/uploadFileExcel', parm, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Token': localStorage.getItem("token")
                    }
                })
                    .then(response => {
                        loading.close();
                        console.log('上传成功----', response.data);
                        var data = JSON.parse(response.data.data)
                        if (data.status == 1) {
                            that.clockShowPresetContent  = data.data;
                        } else {
                            this.$message(response.data.msg);
                        }
                    })
                    .catch(error => {
                        loading.close();
                        console.log('上传error', error);
                    });
            },
            toAddRoster(){
                this.nameId = '';
                this.clockShowPresetTitle = '';
                this.clockShowPresetContent = '';
                this.addNameListDrawer = true;
            },
            // 添加预存名单列表
            async clockPresetsAdd(){
                let arr = this.clockShowPresetContent.split(/[\n\s+,，；;]/g);
                if(arr.length>100){
                    this.$message('名单人数不能超过100');
                    return false
                }
                if(this.clockShowPresetTitle == ''){
                    this.$message('名单标题不可以为空');
                    return false
                }
                if(this.clockShowPresetContent == ''){
                    this.$message('预存名单不可以为空');
                    return false
                }
                // 添加
                if (this.nameId == ''){
                    const {
                        msg,
                        status
                    } = await clockPresetsAdd({
                        "clockPresetTitle": this.clockShowPresetTitle,
                        "clockPresetContent": this.clockShowPresetContent
                    })
                    if(status == 1){
                        this.$message(msg);
                        this.clockPresetsList()
                    }
                    this.$message(msg);
                    this.addNameListDrawer = false;
                }
                // 编辑
                if (this.nameId != ''){
                    const {
                        msg,
                        status
                    } = await clockPresetsUpdate({
                        "clockPresetTitle": this.clockShowPresetTitle,
                        "clockPresetContent": this.clockShowPresetContent,
                        "id":this.id
                    })
                    if(status == 1){
                        this.clockPresetsList()
                    }
                    this.$message(msg);
                    this.addNameListDrawer = false;
                }
            },
            openCus(){
                this.copyShowWriting = this.copywriting;
                this.copyShowImage = this.copyimage;
                this.customizeDrawer = true;
            },
            // 下载excel模板
            downExam(){
                window.open(serverConfig.baseURL+'/excel/模板.xlsx','_self')
            },
            sureCoverImgFun(){
                this.copywriting = this.copyShowWriting;
                this.copyimage = this.copyShowImage;
                this.customizeDrawer = false;
            },
            openNameListDrawerFun(){
                // 弹框赋值
                this.nameShowContent = this.storgeName.join()
                this.openNameListDrawer = true;
            },
            emptyName(){
                this.clockShowPresetContent = ''
            },
            // 导入
            importExcel(event){
                var that = this;
                this.selectedFile = event.target.files[0];
                let lastArr = this.selectedFile.name.split('.')

                if (lastArr[1] != "xls" && lastArr[1] != "xlsx") {
                    this.$message('文件格式不正确');
                    return;
                }

                const loading = this.$loading({
                    lock: true,
                    text: '上传中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var parm = {
                    file: this.selectedFile,
                    type: 'modularFile'
                }
                axios.post(serverConfig.baseURL+'/file/uploadFileExcel', parm, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Token': localStorage.getItem("token")
                    }
                })
                    .then(response => {
                        loading.close();
                        console.log('上传成功----', response.data);
                        var data = JSON.parse(response.data.data)
                        if (data.status == 1) {
                            that.nameShowContent  = data.data;
                        } else {
                            this.$message(response.data.msg);
                        }
                        that.importVisible = false;
                    })
                    .catch(error => {
                        loading.close();
                        console.log('上传error', error);
                    });

            },
            customizeClose(){
                this.customizeDrawer = false;
            },
            // 上传自定义封面
            uploadCopyImage(event){
                this.selectedFile = event.target.files[0];
                var pettern = /^image/;
                if (!pettern.test(this.selectedFile.type)) {
                    this.$message('文件格式不正确');
                    return;
                }
                if (this.selectedFile.size / 1048576 > 5) {
                    this.$message('图片大小小于5M');
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var parm = {
                    file: this.selectedFile,
                    type: 'modularImg'
                }
                axios.post(serverConfig.baseURL+'/file/uploadFile', parm, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Token': localStorage.getItem("token")
                    }
                })
                    .then(response => {
                        loading.close();
                        console.log(response,'response----response')
                        var data = response.data
                        if (data.status == 1) {
                            this.copyShowImage = data.msg;
                        } else {
                            this.$message(response.data.msg);
                        }
                    })
                    .catch(error => {
                        loading.close();
                        console.log('上传error', error);
                    });
            },
            delCopyImage(){
                this.copyShowImage = ''
            },
            clearName(){
                this.nameShowContent = '';
            },
            nameListClose(){
                this.openNameListDrawer = false;
            },
            importClose(){
                this.importVisible = false;
            },
            addNameDrawer(){
                this.addNameListDrawer = false;
            },
            closeAddNameDrawer(){
                this.addNameListDrawer = false;
            },
            nameListDrawerFun(){
                this.nameListDrawer = false;
            },
            // 复制填表项
            copyThisSignField(sonIndex,fatherIndex){
                var data = JSON.parse(JSON.stringify(this.listList[fatherIndex].signField[sonIndex]));
                this.listList[fatherIndex].signField.splice(sonIndex+1,0,data)
            },
            // 月截止日期处理
            endMonthFunFirst(e){
                this.monthDay = e;
                this.formClock.dayOfDate = e;
            },
            endMonthFunSecond(e){
                var str = this.endMonthArr2[e].label;
                this.formClock.timeOfDay = str + ':' + this.endMonthArr2[this.monthMinute].label
            },
            endMonthFunThird(e){
                var str = this.endMonthArr3[e].label;
                this.formClock.timeOfDay = this.endMonthArr2[this.monthHour].label + ':' + str
            },
            // 周截止日期处理
            endWeekFunFirst(e){
                this.weekDay = e;
                this.formClock.dayOfDate = e;
            },
            clockMultiPeriodSwitchFn(e){
                this.formClock.multiPeriodSwitch = e;
                if (e == 2){
                    this.formClock.clockInTimeSlot.forEach((item,index) => {
                        if (index >= 1){
                            item.ifDel = 1
                        }
                    })
                }
            },
            // 添加时间段
            addTimes(){
                var _json = {
                    startTime: "00:00",
                    endTime: "23:59",
                    startAndEnd:['00:00','23:59'],
                    ifDel: 2,
                    randomDecimal: Date.now(),
                }
                this.formClock.clockInTimeSlot.push(_json)
                this.$forceUpdate()
            },
            timesDel(idx){
                var l = 0;
                this.formClock.clockInTimeSlot.forEach((item) => {
                    if (item.ifDel == 2){
                        l++
                    }
                })
                if (l == 1){

                    this.$message('至少保留一个时间段');
                    return false
                }
                this.formClock.clockInTimeSlot[idx].ifDel = 1;
                this.$forceUpdate()
            },
            // 时间段选择
            clockInTimeSlot(e,idx){
                this.formClock.clockInTimeSlot[idx].startAndEnd = e;
                this.formClock.clockInTimeSlot[idx].startTime = e[0];
                this.formClock.clockInTimeSlot[idx].endTime = e[1];
            },

            clockLocationFn(e){
                this.formClock.clockLocationStatus = e;
                this.addMapType = 1;
            },

            clockRangeChange(e){
                console.log(e)
                this.formClock.clockRange = e;
            },

            // 日期多选
            multipleDateSure(e){
                console.log(e,'多选日期哦哦')
                this.customDateArr = e;
                this.formClock.customDate = e.join(',')
            },
            // -打卡频率
            clickin(e){
                this.CheckInFrequency.forEach( item => {
                    if (item.value == e){
                        this.selectData = item.label;
                    }
                })

                this.selectFrequency = e;
                this.formClock.clockFrequencyType = e;
                // 每月
                if (e == 1){
                    this.formClock.timeOfDay = '20:00';
                    // 日期处理
                    this.lastDayOfMonth = this.lastDayOfMonth.slice(-2);
                    // 如果选择了开始时间
                    var beginTime = this.activityDetail.beginTime;
                    if (beginTime){
                        var year = beginTime.substring(0,4);
                        var month = beginTime.substring(5,7);
                        const lastDay = new Date(year, month, 0).getDate();
                        this.lastDayOfMonth = lastDay;
                    }
                    this.formClock.dayOfDate = this.lastDayOfMonth;
                    this.monthDay = Number(this.lastDayOfMonth);
                    this.monthHour = 20;
                    this.monthMinute = 0;
                    this.endMonthValue = this.formClock.dayOfDate + '号 ' + this.formClock.timeOfDay;
                }
                // 每周
                if (e == 2){
                    this.formClock.timeOfDay = '20:00';
                    this.formClock.dayOfDate = '6';
                    this.weekDay = 6;
                    this.weekHour = 20;
                    this.weekMinute = 0;
                    this.endWeekValue = '周' + this.weekChineseNumbers[this.formClock.dayOfDate] + ' ' + this.formClock.timeOfDay;
                }
                // 每天
                if (e == 3){
                    this.formClock.clockFrequency = '0,1,2,3,4,5,6'
                }
                // 法定工作日，法定节假日，自定义周几打卡，自定义日期打卡，一次性打卡 设置一样

            },
            candidateChange(e){
                if (this.selectFrequency == 6){
                    this.formClock.clockFrequency = e.join(',')
                }
                if (this.selectFrequency == 3){
                    this.formClock.clockFrequency = '0,1,2,3,4,5,6'
                }
            },
            clockMaxSwitchFn(e){
                this.formClock.clockMaxSwitch = e;
                if (e == 2){
                    this.formClock.clockMaxNum = 1;
                }
            },
            checkinRangeChange(e){
                this.checkinrange = e;
            },
            signInfoIsOpenFun(e) {
                this.signInfoStatus = e;
                this.activityDetail.signInfoStatus = e;
            },
            signChange(e){
                this.updateSignInfo = e;
            },
            deadlineTimeFun(e){
                this.deadline = e;
                this.activityDetail.registrationtime = e[0]
                this.activityDetail.endregistrationtime = e[1]
            },
            deadCancelTimeFun(e){
                this.deadCancelline = e;
                this.activityDetail.removeSignBeginTime = e[0]
                this.activityDetail.removeSignEndTime = e[1]
            },
            actStartAndEndTimeFun(e){
                this.actStartAndEndTime = e;
                this.activityDetail.beginTime = e[0]
                this.activityDetail.endTime = e[1]
            },
            removeSignChange(e){
                this.removeSignStatus = e;
                this.activityDetail.removeSignStatus = e
            },
            addInp(item, index,fatherIndex){
                var inp = {
                    value: ''
                }
                this.listList[fatherIndex].signField[index].content.push(inp)
                this.$forceUpdate()
            },
            // 删除添加项
            delInp(item,index,indexIdx,listIndex) {
                console.log(item, index,indexIdx,'123456');
                this.listList[listIndex].signField[indexIdx].content.splice(index, 1)
                this.$forceUpdate()
            },
            // 是否必填
            isRequiredChange(e,sonIndex,fatherIndex){
                this.listList[fatherIndex].signField[sonIndex].isRequired = (e == true?1:0);
            },
            // 是否公开
            isOpenChange(e,sonIndex,fatherIndex){
                this.listList[fatherIndex].signField[sonIndex].isOpen = (e == true?1:0);
            },
            // 是否重复
            isUniqueCheckChange(e,sonIndex,fatherIndex){
                this.listList[fatherIndex].signField[sonIndex].isUniqueCheck = (e == true?1:0);
            },
            // 格式选择
            bindValidatePickerChange(e,sonIndex,fatherIndex){
                this.validateIndex = e;
                this.commType =  this.listList[fatherIndex].signField[sonIndex].type;
                console.log(this.commType,'this.commType')
                this.listList[fatherIndex].signField[sonIndex].isFormatCheck = this.validateIndex;
            },
            // 日期格式
            bindValidateTimePickerChange(e,sonIndex,fatherIndex){
                this.validateIndex = e;
                this.listList[fatherIndex].signField[sonIndex].timeSelectType = this.validateIndex;
            },
            // 省市区
            bindValidateAreaPickerChange(e,sonIndex,fatherIndex){
                this.validateIndex = e;
                this.listList[fatherIndex].signField[sonIndex].areaSelectType = this.validateIndex;
            },
            // 打开抽屉
            openfieldList(){
                this.formDrawerShow = true;
            },


            openFullScreen2() {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                setTimeout(() => {
                    loading.close();
                }, 2000);
            },
            // 上传文件
            handleFileUpload(event,idx){
                var that = this;
                console.log(event.target.value,'上传的文件啊，多少个')
                this.modulCommonIndex = idx;
                this.selectedFile = event.target.files;
                console.log(this.selectedFile,'文件信息啊')
                // 加载中
                const loading = this.$loading({
                    lock: true,
                    text: '上传中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                for (let i = 0; i < this.selectedFile.length; i++) {
                    let name = this.selectedFile[i].name;
                    let size = this.selectedFile[i].size;
                    // 判断后缀名
                    let lastArr = this.selectedFile[i].name.split('.')
                    if (lastArr[1] != "pdf" && lastArr[1] != "ppt" && lastArr[1] !=
                        "doc" && lastArr[1] != "docx" && lastArr[1] != "xls" && lastArr[1] != "xlsx") {
                        loading.close();
                        event.target.value = '';
                        this.$message('文件格式不正确');
                        return;
                    } else {
                        if (this.selectedFile[i].size / 1048576 > 10) {
                            loading.close();
                            event.target.value = '';
                            this.$message('请上传大小不超过10MB文件');
                            break;
                        } else {
                            var parm = {
                                file: this.selectedFile[i],
                                type: 'modularFile'
                            }
                            axios.post(serverConfig.baseURL+'/file/uploadFile', parm, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Access-Token': localStorage.getItem("token")
                                }
                            })
                                .then(response => {
                                    console.log('文件上传成功', response);
                                    loading.close();
                                    if (response.data.status == 1) {
                                        var _json = {
                                            name: name,
                                            size: size,
                                            fileUrl: response.data.msg,
                                            lastName: lastArr[1],
                                        }
                                        // console.log(_json,'json三生三世')
                                        that.modularList[this.modulCommonIndex].info.filesArr.push(_json);

                                    }
                                    console.log(that.modularList[this.modulCommonIndex], "文件啊3333333333")
                                })
                                .catch(error => {
                                    event.target.value = '';
                                    loading.close();
                                    console.log('err:', error);
                                });
                        }
                    }
                }

            },
            // 上传音频
            handleMpThreeUpload(event,idx){
                var that = this;
                this.modulCommonIndex = idx;
                this.selectedFile = event.target.files;
                console.log(event,'文件信息啊--音频')

                // 加载中
                const loading = this.$loading({
                    lock: true,
                    text: '上传中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                for (let i = 0; i < this.selectedFile.length; i++) {
                    let name = this.selectedFile[i].name;
                    let size = this.selectedFile[i].size;
                    // 判断后缀名
                    let lastArr = this.selectedFile[i].name.split('.')
                    if (lastArr[1] != "mp3") {
                        loading.close();
                        event.target.value = '';
                        this.$message('文件格式不正确');
                        return;
                    } else {
                        if (this.selectedFile[i].size / 1048576 > 10) {
                            loading.close();
                            event.target.value = '';
                            this.$message('请上传大小不超过10MB文件');
                            break;
                        } else {
                            var parm = {
                                file: this.selectedFile[i],
                                type: 'modularMP3'
                            }

                            axios.post(serverConfig.baseURL+'/file/uploadFile', parm, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Access-Token': localStorage.getItem("token")
                                }
                            })
                                .then(response => {
                                    console.log('文件上传成功', response);
                                    loading.close();
                                    if (response.data.status == 1) {
                                        var _json = {
                                            name: name,
                                            size: size,
                                            fileUrl: response.data.msg,
                                            lastName: lastArr[1],
                                        }
                                        // console.log(_json,'json三生三世')
                                        that.modularList[this.modulCommonIndex].info.filesArr.push(_json);

                                    }
                                    console.log(that.modularList[this.modulCommonIndex], "文件啊3333333333")
                                })
                                .catch(error => {
                                    loading.close();
                                    console.log('err:', error);
                                });
                        }
                    }
                }
            },


            // 上传视频
            handleMpFourUpload(event,idx){
                console.log('视频啊=======')
                var that = this;
                this.modulCommonIndex = idx;
                this.selectedFile = event.target.files[0];
                console.log(this.selectedFile,'文件信息啊7878787')
                let lastArr = this.selectedFile.name.split('.')
                console.log(lastArr,'格式不对？？？')
                if (lastArr[1] != "mp4") {
                    this.$message('文件格式不正确');
                    event.target.value = '';
                    return;
                }
                if (this.selectedFile.size / 1048576 > 10) {
                    this.$message('请上传大小不超过10MB文件');
                    event.target.value = '';
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var parm = {
                    file: this.selectedFile,
                    type: 'modularMP4'
                }
                axios.post(serverConfig.baseURL+'/file/uploadFile', parm, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Token': localStorage.getItem("token")
                    }
                })
                    .then(response => {
                        loading.close();
                        console.log('上传成功', response);
                        that.modularList[idx].info.fileUrl = response.data.msg
                        that.modularList[idx].info.name = that.selectedFile.name
                        that.modularList[idx].info.size = that.selectedFile.size
                        that.modularList[idx].info.lastName = lastArr[1]
                    })
                    .catch(error => {
                        loading.close();
                        console.log('图片上传失败', error);
                    });
            },
            // 上传图片
            handleImgUpload(event,idx) {
                this.modulCommonIndex = idx;
                this.selectedFile = event.target.files;
                console.log(event,'event')
                var pettern = /^image/;
                for (let i = 0; i < 9; i++){
                    console.log(this.selectedFile[i].type,'type没有吗')
                    if (!pettern.test(this.selectedFile[i].type)) {
                        this.$message('文件格式不正确');
                        event.target.value = '';
                        return;
                    }
                    if (this.selectedFile[i].size / 1048576 > 5) {
                        this.$message('请上传大小不超过5MB文件');
                        event.target.value = '';
                    } else {
                        var parm = {
                            file: this.selectedFile[i],
                            type: 'modularImg'
                        }

                        axios.post(serverConfig.baseURL+'/file/uploadFile', parm, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Access-Token': localStorage.getItem("token")
                            }
                        })
                            .then(response => {
                                console.log('图片上传成功', response);
                                if (response.data.status == 1) {
                                    this.modularList[idx].info.imgarr.push(response.data.msg);
                                }
                                console.log(this.modular[idx], "3333333333")
                            })
                            .catch(error => {
                                event.target.value = ''
                                console.log('err:', error);
                            });
                    }

                }

            },
            // 上传小图
            handleSmallImgUpload(event,idx){
                this.modulCommonIndex = idx;
                // 9个
                const imgCounts = this.modularList[idx].info.imgarr.length;
                const theRest = 9 - imgCounts;
                if (event.target.files.length > theRest){
                    this.$message('只能上传9张小图');
                    return;
                }
                this.selectedFile = event.target.files;
                console.log(event,'event')
                var pettern = /^image/;
                for (let i = 0; i < 9; i++){
                    console.log(this.selectedFile[i].type,'type没有吗')
                    if (!pettern.test(this.selectedFile[i].type)) {
                        this.$message('文件格式不正确');
                        return;
                    }
                    if (this.selectedFile[i].size / 1048576 > 5) {
                        this.$message('图片大小小于5M');
                    } else {
                        var parm = {
                            file: this.selectedFile[i],
                            type: 'modularImg'
                        }

                        axios.post(serverConfig.baseURL+'/file/uploadFile', parm, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Access-Token': localStorage.getItem("token")
                            }
                        })
                            .then(response => {
                                console.log('图片上传成功', response);
                                if (response.data.status == 1) {
                                    this.modularList[idx].info.imgarr.push(response.data.msg);
                                }
                                console.log(this.modular[idx], "3333333333")
                            })
                            .catch(error => {
                                event.target.value = ''
                                console.log('err:', error);
                            });
                    }

                }
            },
            submitUploadBigImage() {
                const formData = new FormData();
                formData.append('image', this.selectedFile);
                var parm = {
                    file: this.selectedFile,
                    type: 'modularImg'
                }

                axios.post(serverConfig.baseURL+'/file/uploadFile', parm, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Access-Token': localStorage.getItem("token")
                        }
                    })
                    .then(response => {
                        console.log('图片上传成功', response);

                    })
                    .catch(error => {
                        console.log('图片上传失败', error);
                    });
            },
            anonymousFun(e){
                this.isanonymous = e;
            },
            copyStatusFun(e){
                this.copyStatus = e;
            },
            forwardFun(e){
                let a = e ? 1 : 2
                this.shareStatus = a;
                this.is_forward = e?1:0;
            },
            anonymouspartFun(e){
                this.anonymousparticipation = e;
            },
            commentFun(e){
                this.commentStatus = e;
            },
            thumbsUpFun(e){
                this.thumbs_up = e;
            },
            isCommentFun(e){
                this.is_comment = e;
            },

            isScoreFun(e) {
                this.is_score = e;
            },
            isDestinationFun(e) {
                this.is_destination = e;
            },
            clockRemindFun(e) {
                this.formClock.clockRemindStatus = e;
            },
            // 打卡提醒时间
            clockTime(e) {
                console.log(e,'时间啊时间啊')
                this.formClock.remindTime = e
            },
            // 表单项上移下移删除
            topNuoPng(item, index, type, listIdx) {
                var data = []
                if (type == 1) {
                    console.log(item, index);
                    data = this.listList
                } else if (type == 2) {
                    if (index == 1) {
                        return false
                    }
                    data = this.listList[listIdx].signField
                } else if (type == 3) {
                    data = this.modular
                }
                console.log(data);
                if (index == 0) {
                    console.log("已经是第一项了");
                    return false
                }
                //在上一项插入该项
                data.splice(index - 1, 0, (data[index]));
                //删除后一项
                data.splice(index + 1, 1);

            },
            // 下移
            bottomNuoPng(item, index, type, listIdx) {
                var data = []
                if (type == 1) {
                    console.log(item, index);
                    data = this.listList
                } else if (type == 2) {
                    data = this.listList[listIdx].signField
                } else if (type == 3) {
                    data = this.modular
                }

                if (index == data.length - 1) {
                    console.log("已经是最后一个了")
                    return false
                }
                //在下一项插入该项
                data.splice(index + 2, 0, (data[index]));
                // 删除前一项
                data.splice(index, 1);

            },
            // 删除表单项
            delMuKuai(itm, ind, idx) {
                this.indexIdx = idx
                console.log(itm, ind, idx,'删除表单项啊啦啦啦');
                if (this.selectType == 'update'){
                    itm['ifDel'] = 1;
                    this.delListInfo.push(itm);
                }
                this.listList[this.indexIdx].signField.splice(ind, 1)
            },
            // 大图上移下移
            upBigImg(index,fatherIndex) { // 上移

                if (index == 0) {
                    this.$message({
                        message: '已经是第一项',
                        type: 'warning'
                    });
                    return false;
                }
                //在上一项插入该项
                this.modularList[fatherIndex].info.imgarr.splice(index - 1, 0, (this.modularList[fatherIndex].info.imgarr[index]));
                //删除后一项
                this.modularList[fatherIndex].info.imgarr.splice(index + 1, 1);

            },
            downBigImg(index,fatherIndex) { // 下移

                if (index == this.modularList[fatherIndex].info.imgarr.length - 1) {
                    this.$message({
                        message: '已经是最后一项',
                        type: 'warning'
                    });
                    return false;
                }
                //在下一项插入该项
                this.modularList[fatherIndex].info.imgarr.splice(index + 2, 0, (this.modularList[fatherIndex].info.imgarr[index]));
                // 删除前一项
                this.modularList[fatherIndex].info.imgarr.splice(index, 1);

            },
            // 大图删除
            delBigImg(index,fatherIndex){
                this.modularList[fatherIndex].info.imgarr.splice(index, 1);
            },
            // 小图删除
            delSmallPic(index,fatherIndex){
                this.modularList[fatherIndex].info.imgarr.splice(index, 1);
            },
            // 文件删除
            delFiles(index,fatherIndex){
                this.modularList[fatherIndex].info.filesArr.splice(index, 1);
            },

            //选择位置
            openAddress(idx) {
                this.addMapType = 1;
                this.addressDialog = true
                this.addressIdx = idx
            },
            openMap(idx) {
                this.modulCommonIndex = idx;
                this.addMapType = 2;
                this.addressDialog = true
                this.addressIdx = idx
            },
            openJoininAddress(){
                this.addMapType = 3;
                this.addressDialog = true
            },

            subAddress() {
                window.addEventListener('message', (event) => {
                    // console.log("this.addressIdx", this.addressIdx)
                    // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
                    const loc = event.data;
                    // console.log(loc,'jjjj')
                    // _this.addressData = []
                    if (loc && loc.module == 'locationPicker') { //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
                        this.address = loc.poiaddress
                        this.poiname = loc.poiname
                        this.latitude = loc.latlng.lat
                        this.longitude = loc.latlng.lng
                    }
                }, false);
            },
            //地图选点确定
            submitMap() {
                if (this.address == null || this.latitude == null || this.longitude == null) {
                    this.$message.error('请先选择地址！');
                    return;
                }
                console.log(this.address,'this.address')

                this.addressDialog = false
                // 模块
                if (this.addMapType == 2){
                    this.modularList[this.modulCommonIndex].info.address = this.address;
                    this.modularList[this.modulCommonIndex].info.latitude = this.latitude;
                    this.modularList[this.modulCommonIndex].info.longitude = this.longitude;
                    this.modularList[this.modulCommonIndex].info.name = this.poiname;
                    console.log(this.modularList[this.modulCommonIndex],'信息拿到了吗')
                    this.modularList[this.modulCommonIndex].location = {
                        lng: this.longitude,
                        lat: this.latitude
                    }
                }
                // 设置
                if (this.addMapType == 3){
                    this.specifyaddress = this.address;
                    this.deliveryaddress =  `${this.latitude},${this.longitude}`;

                }
                // 打卡
                if (this.addMapType == 1){
                    var info = {
                        name: this.poiname,
                        address: this.address,
                        latitude: this.latitude,
                        longitude: this.longitude,
                    }
                    console.log(info,'地址信息-打卡啊')
                    this.formClock.clockLocationInfo = JSON.stringify(info);
                    this.formClock.address = this.address;
                }
            },

            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(() => {
                        done();
                    })
                    .catch(() => {});
            },
            formDrawerClose(){
                this.formDrawerShow = false;
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePictureCardPreview(file) {
                console.log(file);
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            submitUpload() {
                this.$refs.upload.submit();
            },

            handlePreview(file) {
                console.log(file);
            },

            getLastDayOfMonth(date = new Date()) {
                // 使用示例
                // console.log(getLastDayOfMonth()); // 输出类似 "2023-05-31" 的字符串，取决于当前日
                // 创建一个新的日期对象，设置为下一个月的第一天
                let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

                // 减去一天，即得到当前月的最后一天
                let lastDay = new Date(nextMonth - 1);

                // 返回结果，根据需要可以返回不同的格式
                // 这里返回的是日期对象的字符串形式
                return lastDay.toISOString().split('T')[0]; // 如果你想要的是 YYYY-MM-DD 格式

                // 或者直接返回日期对象
                // return lastDay;
            },
            // 对象数组进行去重
            uniqueBy(a, key) {
                const seen = new Set();
                return a.filter((item) => {
                    const k = key(item);
                    return seen.has(k) ? false : seen.add(k);
                });
            },
            getClickInfo(address){
                console.log(address,'地图点击')
                this.markLocation.lat = address.point.lat;
                this.markLocation.lng = address.point.lng;
            },
            searchMap(address){
                // 创建地址解析器实例，平时用不了，加个window完美胡恶化！
                let myGeo = new window.BMap.Geocoder();
                // 因为下面那个方法是异步的，你又不能在里面this，
                //所以先在外面把this赋值给一个变量self，然后在里面就可以接了！
                let self = this;
                new myGeo.getPoint(address, function(e){
                    console.log(e);
                    self.markLocation.lat = e.lat;
                    self.markLocation.lng = e.lng;
                });
            },
            checkDetail(e){
                console.log(e,'uuuuuu')
            },
            // 模块上移下移删除
            moveUp(index){
                if (index == 0) {
                    this.$message({
                        message: '已经是第一项',
                        type: 'warning'
                    });
                    return false;
                }
                //在上一项插入该项
                this.modularList.splice(index - 1, 0, (this.modularList[index]));
                //删除后一项
                this.modularList.splice(index + 1, 1);
            },
            moveDown(index){
                if (index == this.modularList.length - 1) {
                    this.$message({
                        message: '已经是最后一项',
                        type: 'warning'
                    });
                    return false;
                }
                //在下一项插入该项
                this.modularList.splice(index + 2, 0, (this.modularList[index]));
                // 删除前一项
                this.modularList.splice(index, 1);
            },
            delmodular(item,index){
                var that = this;
                this.$confirm('是否确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (item.id != null && item.id != undefined && that.selectType == "update") {
                        that.modularDelIds.push(item.id)
                    }
                    that.modularList.splice(index, 1)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 查看大图
            seeBigImg(src){
                this.previewImgSrc = src;
                this.previewImgShow = true;
            },
            hidePreview(){
                this.previewImgShow = false
            },

            timestampToTime(date) {
                if (date == "" || !date) {
                    return "";
                }
                var date2 = new Date(date);
                var y = date2.getFullYear();
                var m = date2.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date2.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date2.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date2.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                var second = date2.getSeconds();
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;

            },

        },
        computed: {
            commonData() {
                return this.$store ? this.$store.state.commonData : null;
            },
            setMb() {
                return function(size, fileType) {
                    if (size == '') {
                        return ''
                    } else {
                        let infoSize = size / 1048576
                        if (fileType == "mp3" || fileType == "MP3" || fileType == "Mp3" || fileType == "mP3") {
                            return "音频大小：" + (Math.floor(infoSize) == 0 ? Math.floor(size / 1024) + "KB" : Math.floor(
                                infoSize) + "MB")
                        } else if (fileType == "mp4" || fileType == "MP4" || fileType == "Mp4" || fileType == "mP4") {
                            return "视频大小：" + (Math.floor(infoSize) == 0 ? Math.floor(size / 1024) + "KB" : Math.floor(
                                infoSize) + "MB")
                        } else {
                            return "文件大小：" + (Math.floor(infoSize) == 0 ? Math.floor(size / 1024) + "KB" : Math.floor(
                                infoSize) + "MB")
                        }
                    }
                }
            },
            setKb() {
                return function(size, fileType) {
                    if (size == "") {
                        return ''
                    } else if (size == 0) {
                        return "文件大小：" + 0 + "KB"
                    } else {
                        let infoSize = size / 1024
                        if (fileType == "mp3" || fileType == "MP3" || fileType == "Mp3" || fileType == "mP3") {
                            return "音频大小：" + Math.floor(infoSize) + "KB"
                        } else if (fileType == "mp4" || fileType == "MP4" || fileType == "Mp4" || fileType == "mP4") {
                            return "视频大小：" + Math.floor(infoSize) + "KB"
                        } else {
                            return "文件大小：" + Math.floor(infoSize) + "KB"
                        }
                    }
                }
            },
            setB() {
                return function(size, fileType) {
                    if (size == "") {
                        return ''
                    } else if (size == 0) {
                        return "文件大小：" + 0 + "B"
                    } else {
                        let infoSize = size
                        if (fileType == "mp3" || fileType == "MP3" || fileType == "Mp3" || fileType == "mP3") {
                            return "音频大小：" + Math.floor(infoSize) + "B"
                        } else if (fileType == "mp4" || fileType == "MP4" || fileType == "Mp4" || fileType == "mP4") {
                            return "视频大小：" + Math.floor(infoSize) + "B"
                        } else {
                            return "文件大小：" + Math.floor(infoSize) + "B"
                        }
                    }
                }
            },
        },
    };
</script>

<template>
    <Layout>

        <div class="row">
            <div class="outBox">
                <div class="bigTitle">{{ selectType=='add'?'创建':'编辑' }}{{ activeBuildTitle }}</div>
                <div class="blockBox">
                    <div class="cannot-edit" @click="cannotEdit" v-if="commonDisabled == true"></div>
<!--                    <div class="block-title">接龙名称</div>-->
                    <div class="b-marT-10 centerTitle">
<!--                        <input type="text" v-model="activityDetail.name" placeholder="请输入接龙标题" style="text-align: center">-->
                        <el-input v-model="activityDetail.name" placeholder="请输入接龙标题" style="text-align: center"></el-input>
                    </div>
                    <div class="b-marT-10" :class="{'zsy':modularList.length <= 1,'nozsy':modularList.length >= 2}">
                        <el-input type="textarea" class="zsy-inner" v-model="activityDetail.content" placeholder="请输入接龙内容（选填）" rows="5"></el-input>
                    </div>
                    <!-- todo 详情页展示列表 -->

                    <div v-for="(item,index) in modularList" :key="index">
                        <!-- 内容 -->
                        <div class="block-for-box" v-if="item.type == 1">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.title" placeholder="内容标题（选填）"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
                            <div class="b-marT-10">
                                <el-input type="textarea" v-model="item.info.value" placeholder="请输入接龙内容（选填）" rows="5"></el-input>
                            </div>
                        </div>
                        <!-- 大图 -->
                        <div class="block-for-box" v-if="item.type == 9">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.value" placeholder="大图"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
                            <div class="b-marT-10" v-for="(imgItem, imgIndex) in item.info.imgarr" :key="imgIndex">
                                <div class="flex-depart-top">
                                    <div class="big-img">
<!--                                        <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/5c2333976171b.jpg" alt="">-->
                                        <img :src="imageUrl + imgItem" alt="">
                                    </div>
                                    <div class="flex-depart-column-top-left b-handle">
                                        <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="upBigImg(imgIndex,index)">
                                        <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="downBigImg(imgIndex,index)">
                                        <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delBigImg(imgIndex,index)">
                                    </div>
                                </div>
                            </div>
                            <div class="addSmallBtn b-marT-10" v-if="item.info.imgarr.length < 9">
                                <input class="updateFileBtn" type="file" id="imageInput" multiple accept="image/*" @change="event => handleImgUpload(event,index)"/>
                                <i class="el-icon-circle-plus"></i>&nbsp;点击上传大图
                            </div>
                        </div>
                        <!-- 小图 -->
                        <div class="block-for-box" v-if="item.type == 3">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.value" placeholder="小图"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
                            <div class="b-marT-10 flex-depart-left-warp ">
                                <div class="smallImgbox seeBigImgHover" v-for="(imgItem, imgIndex) in item.info.imgarr" :key="imgIndex">
                                    <div class="handle2">
                                        <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-chakandatu.svg" @click="seeBigImg(imgItem)" alt="">
                                        <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchuxiaotu.svg" @click="delSmallPic(imgIndex,index)" alt="">
                                    </div>
                                    <div class="imgBg" :style="{background: `url(${imageUrl+imgItem}) no-repeat center`,backgroundSize: 'cover'}"></div>
                                </div>
                                <div class="upl-smallImg" v-if="item.info.imgarr.length < 9">
                                    <input class="updateFileBtn" type="file" id="imageInput2" multiple accept="image/*" @change="event => handleSmallImgUpload(event,index)"/>
                                    <i class="el-icon-circle-plus"></i>
                                    <span>上传小图</span>
                                </div>
                            </div>
                        </div>
                        <!-- 地点 -->
                        <div class="block-for-box" v-if="item.type == 4">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.value" placeholder="地址"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
<!--                            <div class="b-marT-10" v-show="item.info.address">-->
<!--                                <baidu-map class="map" @click="getClickInfo" :center="item.location">-->
<!--                                    &lt;!&ndash; 右上角比例尺控件 &ndash;&gt;-->
<!--                                    <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>-->
<!--                                    &lt;!&ndash; 右上角缩放控件 &ndash;&gt;-->
<!--                                    <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>-->
<!--                                    &lt;!&ndash; 右下角加入定位控件 &ndash;&gt;-->
<!--                                    <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>-->
<!--                                    &lt;!&ndash; bm-marker 就是标注点，定位在 markLocation 的经纬度上 &ndash;&gt;-->
<!--                                    <bm-marker :position="item.location" @click="checkDetail"></bm-marker>-->
<!--                                </baidu-map>-->
<!--                            </div>-->

                            <div class="b-marT-10">
                                <div style="font-weight: bold">{{item.info.name}}</div>
                                <div>{{item.info.address}}</div>
                                <el-button v-if="!item.info.address" @click="openMap(index)">选择位置</el-button>
                                <el-button v-if="item.info.address" @click="openMap(index)">重新选择</el-button>
                            </div>
                        </div>
                        <!-- 文件 -->
                        <div class="block-for-box" v-if="item.type == 5">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.value" placeholder="文件"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
                            <div class="b-marT-10">
                                <div class="filesStyle flex-depart-center hoverColor1" v-for="(fileItem, fileIndex) in item.info.filesArr" :key="fileIndex">
                                    <div class="flex-depart-left">
                                        <img v-if="fileItem.lastName == 'doc'" :src="imageUrl + '/static/announcement/word.png'"
                                             class="Img3">
                                        <img v-else-if="fileItem.lastName == 'docx'"
                                             :src="imageUrl + '/static/announcement/word.png'" class="Img3">
                                        <img v-else-if="fileItem.lastName == 'pdf'"
                                             :src="imageUrl + '/static/announcement/PDF.png'" class="Img3">
                                        <img v-else-if="fileItem.lastName == 'ppt'"
                                             :src="imageUrl + '/static/announcement/PPT.png'" class="Img3">
                                        <img v-else-if="fileItem.lastName == 'xls'" :src="imageUrl + '/static/index/excel.png'"
                                             class="Img3">
                                        <img v-else-if="fileItem.lastName == 'xlsx'" :src="imageUrl + '/static/index/excel.png'"
                                             class="Img3">
                                        <span>{{fileItem.name}}</span>/
                                        <span v-if="fileItem.size / 1024 <= 1">{{setB(fileItem.size,fileItem.lastName)}}</span>
                                        <span v-else-if="fileItem.size / 1024 >= 1 && fileItem.size / 1048576 <= 1">{{setKb(fileItem.size,fileItem.lastName)}}</span>
                                        <span v-else-if="fileItem.size / 1048576 >= 1">{{setMb(fileItem.size,fileItem.lastName)}}</span>

                                    </div>
                                    <i class="el-icon-delete del" @click="delFiles(fileIndex,index)"></i>
                                </div>
                            </div>
                            <div class="addSmallBtn b-marT-10">
                                <input class="updateFileBtn" type="file" id="imageInput3" multiple
                                       accept=".xls,.xlsx,.docx,.pdf,.ppt" @change="event => handleFileUpload(event,index)"/>
                                <i class="el-icon-circle-plus"></i>&nbsp;点击上传文件
                            </div>
                        </div>
                        <!-- 音频 -->
                        <div class="block-for-box" v-if="item.type == 6">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.value" placeholder="音频"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
                            <div class="b-marT-10">
                                <div class="filesStyle flex-depart-center hoverColor1" v-for="(fileItem, fileIndex) in item.info.filesArr" :key="fileIndex">
                                    <div class="flex-depart-left" @click="openMusic(fileItem)" style="cursor: pointer">
                                        <img :src="imageUrl + '/static/announcement/MP3.png'" class="Img3">
                                        <span>{{fileItem.name}}</span>/
                                        <span v-if="fileItem.size / 1024 <= 1">{{setB(fileItem.size,fileItem.lastName)}}</span>
                                        <span v-else-if="fileItem.size / 1024 >= 1 && fileItem.size / 1048576 <= 1">{{setKb(fileItem.size,fileItem.lastName)}}</span>
                                        <span v-else-if="fileItem.size / 1048576 >= 1">{{setMb(fileItem.size,fileItem.lastName)}}</span>
                                    </div>
                                    <i class="el-icon-delete del" @click="delFiles(fileIndex,index)"></i>
                                </div>
                            </div>
                            <div class="addSmallBtn b-marT-10">
                                <input class="updateFileBtn" type="file" id="imageInput4" accept=".mp3" multiple @change="event => handleMpThreeUpload(event,index)"/>
                                <i class="el-icon-circle-plus"></i>&nbsp;点击上传音频
                            </div>
                        </div>
                        <!-- 视频 -->
                        <div class="block-for-box" v-if="item.type == 7">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.value" placeholder="视频"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
                            <div class="b-marT-10" v-if="item.info.fileUrl != ''">
                                <video controls width="450">
                                    <source :src="imageUrl+`${item.info.fileUrl}`" type="video/mp4">
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </div>
                            <div class="addSmallBtn b-marT-10">
                                <input class="updateFileBtn" type="file" id="imageInput5" accept=".mp4" @change="event => handleMpFourUpload(event,index)"/>
                                <i class="el-icon-circle-plus"></i>&nbsp;{{item.info.fileUrl==''?'点击上传视频':'更改视频'}}
                            </div>
                        </div>

                        <!-- 可复制内容 -->
                        <div class="block-for-box" v-if="item.type == 13">
                            <div class="flex-depart-center">
                                <div class="tl-item bf-item flex-depart-left">
                                    <el-input class="f-ipt" v-model="item.info.value" placeholder="可复制内容"></el-input>
                                </div>
                                <div class="f-handle tr-item">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shangyi.png" alt="" @click="moveUp(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-xiayi.png" alt="" @click="moveDown(index)">
                                    <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shanchu1.png" alt="" @click="delmodular(item,index)">
                                </div>
                            </div>
                            <div class="b-marT-10">
                                <el-input type="textarea" v-model="item.info.value" rows="5"  placeholder="请输入可复制内容"></el-input>
                            </div>
                        </div>

                    </div>

                    <!-- todo 详情页展示添加按钮 -->
                    <div class="handle-box flex-depart-left b-marT-10">
                        <div class="flex-depart-left h-item" @click="addModule(0)">
                            <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-zdingyi.svg" alt="">
                            <span>自定义内容</span>
                        </div>
                        <div class="flex-depart-left h-item" @click="addModule(7)">
                            <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-datu.svg" alt="">
                            <span>大图</span>
                        </div>
                        <div class="flex-depart-left h-item" @click="addModule(2)">
                            <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-jiugongge.svg" alt="" style="margin-right: 3px">
                            <span>九宫格图片</span>
                        </div>
                        <div class="flex-depart-left h-item" @click="addModule(3)">
                            <i style="font-size:17px;margin-right: 3px" class="el-icon-location-outline"></i>
                            <span>位置</span>
                        </div>
                        <div class="flex-depart-left h-item" @click="addModule(4)">
                            <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-wenjian.svg" alt="">
                            <span>文件</span>
                        </div>
                        <div class="flex-depart-left h-item" @click="addModule(5)">
                            <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-yinpin.svg" alt="">
                            <span>音频</span>
                        </div>
                        <div class="flex-depart-left h-item" @click="addModule(6)">
                            <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-shipin.svg" alt="">
                            <span>视频</span>
                        </div>
                        <div class="flex-depart-left h-item" @click="addModule(11)">
                            <img src="http://jisuzhizuo.oss-cn-huhehaote.aliyuncs.com/12jielong/bdJL/pc-kefuzi.svg" alt="">
                            <span>可复制内容</span>
                        </div>
                    </div>
                </div>
                <div class="blockBox">
                    <div class="cannot-edit" @click="cannotEdit" v-if="commonDisabled == true"></div>
                    <template>
                        <span class="s-name-item" v-for="(item,index) in storgeName" :key="index">
                            {{item.length > 4? item.substring(0,4)+'...':item}}
                        </span>
                    </template>

                    <div class="flex-depart-center-center">
                        <div class="addBtnStyle" @click="openNameListDrawerFun">
                            <i class="el-icon-circle-plus-outline"></i>添加接龙名单
                        </div>
                    </div>
                </div>

                <!-- todo 填表项循环 -->
                <div class="blockBox">
                    <div class="cannot-edit" @click="cannotEdit" v-if="commonDisabled == true"></div>
                    <div class="block-title">填表项设置</div>

                    <div v-for="(listItem,listIndex) in listList" :key="listIndex">
                        <div class="b-marT-10">
                            <el-input class="f-ipt" v-model="listItem.signListName" :disabled="commonDisabled" placeholder="分组名称" maxlength="6"></el-input>
                        </div>
                        <div v-for="(fieldItem,fieldIndex) in listItem.signField" :key="fieldIndex">
                            <template v-if="fieldItem.type == 1">
                                <!--姓名等-->
                                <div class="f-block" v-if="fieldItem.num==3">
                                    <div class="flex-depart-center">
                                        <div class="tl-item flex-depart-left">
                                            {{fieldIndex+1}}、
                                            <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                      :disabled="commonDisabled"
                                                      placeholder="姓名"></el-input>
                                        </div>
                                        <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                            <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                        </div>
                                    </div>
                                    <div class="flex-depart-center b-marT-10">
                                        <div>
                                            <span>文本格式：</span>
                                            <el-select v-model="fieldItem.isFormatCheck" placeholder="请选择" @change="$event => bindValidatePickerChange($event,fieldIndex,listIndex)">
                                                <el-option
                                                        v-for="item in validateArr"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                            <el-checkbox style="margin-left: 15px" v-model="fieldItem.isUniqueCheckChecked" @change="$event => isUniqueCheckChange($event,fieldIndex,listIndex)">不可以重复</el-checkbox>
                                        </div>
                                        <div class="flex-depart-center-end checkedState">
                                            <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                            <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                            <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                                <i class="el-icon-document-copy"></i>复制
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="f-block" v-else-if="fieldItem.num==4">
                                    <div class="flex-depart-center">
                                        <div class="tl-item flex-depart-left">
                                            {{fieldIndex+1}}、
                                            <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                      :disabled="commonDisabled"
                                                      placeholder="姓名"></el-input>
                                        </div>
                                        <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                            <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                        </div>
                                    </div>
                                    <div class="flex-depart-center b-marT-10">
                                        <div>
                                            <span>文本格式：</span>
                                            <el-select v-model="fieldItem.areaSelectType" placeholder="请选择" @change="$event => bindValidatePickerChange($event,fieldIndex,listIndex)">
                                                <el-option
                                                        v-for="item in validateArr"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                            <el-checkbox style="margin-left: 15px" v-model="fieldItem.isUniqueCheckChecked" @change="$event => isUniqueCheckChange($event,fieldIndex,listIndex)">不可以重复</el-checkbox>
                                        </div>
                                        <div class="flex-depart-center-end checkedState">
                                            <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                            <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                            <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                                <i class="el-icon-document-copy"></i>复制
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="f-block" v-else>
                                    <div class="flex-depart-center">
                                        <div class="tl-item flex-depart-left">
                                            {{fieldIndex+1}}、
                                            <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                      :disabled="commonDisabled"
                                                      placeholder="姓名"></el-input>
                                        </div>
                                        <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                            <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                        </div>
                                    </div>
                                    <div class="flex-depart-center b-marT-10">
                                        <div>
                                            <span>文本格式：</span>
                                            <el-select v-model="fieldItem.isFormatCheck" placeholder="请选择" @change="$event => bindValidatePickerChange($event,fieldIndex,listIndex)">
                                                <el-option
                                                        v-for="item in validateArr"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                            <el-checkbox style="margin-left: 15px" v-model="fieldItem.isUniqueCheckChecked" @change="$event => isUniqueCheckChange($event,fieldIndex,listIndex)">不可以重复</el-checkbox>
                                        </div>
                                        <div class="flex-depart-center-end checkedState">
                                            <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                            <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                            <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                                <i class="el-icon-document-copy"></i>复制
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!--数量-->
                            <div class="f-block" v-if="fieldItem.type == 13">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="数量"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 手机号 -->
                            <div class="f-block" v-if="fieldItem.type == 11">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="手机号"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 身份证-->
                            <div class="f-block" v-if="fieldItem.type == 10">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="身份证"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 位置 -->
                            <div class="f-block" v-if="fieldItem.type == 12">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="你所在的位置"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 单选/性别/其他 -->
                            <template v-if="fieldItem.type == 2">
                                <div class="f-block" v-if="fieldItem.num == 1">
                                    <div class="flex-depart-center">
                                        <div class="tl-item flex-depart-left">
                                            {{fieldIndex+1}}、
                                            <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                      :disabled="commonDisabled"
                                                      placeholder="请输入自定义内容"></el-input>
                                        </div>
                                        <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                            <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                        </div>
                                    </div>
                                    <div class="flex-depart-left selectBox-item b-marT-10" v-for="(itexb,idx) in fieldItem.content" :key="idx">
                                        {{idx+1}}、<el-input v-model="itexb.value" placeholder="请输入自定义内容"></el-input>
                                        <i v-if="commonDisabled == false" class="el-icon-remove-outline" @click="delInp(fieldItem,idx,fieldIndex,listIndex)"></i>
                                    </div>
                                    <div class="b-marT-10 addOptStyle"
                                         @click="addInp(fieldItem,fieldIndex,listIndex)"
                                         v-show="commonDisabled == false">添加单选选项</div>
                                    <div class="flex-depart-center b-marT-10">
                                        <div></div>
                                        <div class="flex-depart-center-end checkedState">
                                            <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                            <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                            <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                                <i class="el-icon-document-copy"></i>复制
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="f-block" v-else-if="fieldItem.num == 2">
                                    <div class="flex-depart-center">
                                        <div class="tl-item flex-depart-left">
                                            {{fieldIndex+1}}、
                                            <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                      :disabled="commonDisabled"
                                                      placeholder="性别"></el-input>
                                        </div>
                                        <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                            <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                        </div>
                                    </div>
                                    <div class="flex-depart-left selectBox-item b-marT-10" v-for="(itexb,idx) in fieldItem.content" :key="idx">
                                        {{idx+1}}、<el-input v-model="itexb.value" placeholder="男" disabled></el-input>
                                        <i v-if="fieldItem.content.length>2" class="el-icon-remove-outline"></i>
                                    </div>
<!--                                    <div class="b-marT-10 addOptStyle"-->
<!--                                         @click="addInp(fieldItem,fieldIndex,listIndex)"-->
<!--                                         v-show="commonDisabled == false">添加选项</div>-->
                                    <div class="flex-depart-center b-marT-10">
                                        <div></div>
                                        <div class="flex-depart-center-end checkedState">
                                            <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                            <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                            <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                                <i class="el-icon-document-copy"></i>复制
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="f-block" v-else>
                                    <div class="flex-depart-center">
                                        <div class="tl-item flex-depart-left">
                                            {{fieldIndex+1}}、
                                            <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                      :disabled="commonDisabled"
                                                      placeholder=""></el-input>
                                        </div>
                                        <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                            <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                            <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                        </div>
                                    </div>
                                    <div class="flex-depart-left selectBox-item b-marT-10" v-for="(itexb,idx) in fieldItem.content" :key="idx">
                                        {{idx+1}}、<el-input v-model="itexb.value" maxlength="50" placeholder="" disabled></el-input>
                                        <i v-if="fieldItem.content.length>2" class="el-icon-remove-outline"></i>
                                    </div>
                                    <div class="b-marT-10 addOptStyle"
                                         @click="addInp(fieldItem,fieldIndex,listIndex)"
                                         v-show="commonDisabled == false">添加选项</div>
                                    <div class="flex-depart-center b-marT-10">
                                        <div></div>
                                        <div class="flex-depart-center-end checkedState">
                                            <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                            <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                            <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                                <i class="el-icon-document-copy"></i>复制
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- 录音 -->
                            <div class="f-block" v-if="fieldItem.type == 7">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="录音"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 视频 -->
                            <div class="f-block" v-if="fieldItem.type == 6">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="请填写视频标题"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 多选 -->
                            <div class="f-block" v-if="fieldItem.type == 4">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="请输入自定义内容"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-left selectBox-item b-marT-10" v-for="(ite1,idx1) in fieldItem.content" :key="idx1">
                                    {{idx1+1}}、<el-input v-model="ite1.value" placeholder="请输入自定义内容"></el-input>
                                    <i v-if="commonDisabled == false" class="el-icon-remove-outline" @click="delInp(fieldItem,idx1,fieldIndex,listIndex)"></i>
                                </div>
                                <div class="b-marT-10 addOptStyle"
                                     @click="addInp(fieldItem,fieldIndex,listIndex)"
                                     v-show="commonDisabled == false">添加多选选项</div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 多行文本 -->
                            <div class="f-block" v-if="fieldItem.type == 9">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="请输入自定义标题"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div>
                                        <span>文本格式：</span>
                                        <el-select v-model="fieldItem.isFormatCheck" placeholder="请选择" @change="$event => bindValidatePickerChange($event,fieldIndex,listIndex)">
                                            <el-option
                                                    v-for="item in validateArr"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-checkbox style="margin-left: 15px" v-model="fieldItem.isUniqueCheckChecked" @change="$event => isUniqueCheckChange($event,fieldIndex,listIndex)">不可以重复</el-checkbox>
                                    </div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 学校/院系/公司/职位/部门/单位 -->
                            <div class="f-block" v-if="fieldItem.type == 21 || fieldItem.type == 22 || fieldItem.type == 25  || fieldItem.type == 26  || fieldItem.type == 27  || fieldItem.type == 28">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="请输入自定义标题"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div>
                                        <span>文本格式：</span>
                                        <el-select v-model="fieldItem.isFormatCheck" placeholder="请选择" @change="$event => bindValidatePickerChange($event,fieldIndex,listIndex)">
                                            <el-option
                                                    v-for="item in validateArr"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 邮箱/工号/学号 -->
                            <div class="f-block" v-if="fieldItem.type == 19 || fieldItem.type == 23 || fieldItem.type == 24">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="请输入自定义标题"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div>
                                        <span>文本格式：</span>
                                        <el-select v-model="fieldItem.isFormatCheck" placeholder="请选择" @change="$event => bindValidatePickerChange($event,fieldIndex,listIndex)">
                                            <el-option
                                                    v-for="item in validateArr"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-checkbox style="margin-left: 15px" v-model="fieldItem.isUniqueCheckChecked" @change="$event => isUniqueCheckChange($event,fieldIndex,listIndex)">不可以重复</el-checkbox>
                                    </div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 水印拍照 -->
                            <div class="f-block" v-if="fieldItem.type == 20">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="水印拍照"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div></div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 日期 -->
                            <div class="f-block" v-if="fieldItem.type == 3">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="请输入自定义标题"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div>
                                        <span>文本格式：</span>
                                        <el-select v-model="fieldItem.timeSelectType" placeholder="请选择" @change="$event => bindValidateTimePickerChange($event,fieldIndex,listIndex)">
                                            <el-option
                                                    v-for="item in timeSetList"
                                                    :key="item.type"
                                                    :label="item.value"
                                                    :value="item.type">
                                            </el-option>
                                        </el-select>
                                        <el-checkbox style="margin-left: 15px" v-model="fieldItem.isUniqueCheckChecked" @change="$event => isUniqueCheckChange($event,fieldIndex,listIndex)">不可以重复</el-checkbox>
                                    </div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 多图 -->
                            <div class="f-block" v-if="fieldItem.type == 5">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="图片"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div class="flex-depart-left">
                                        上传图片数量
                                        <div style="width: 80px" class="flex-depart-left">
                                            <el-input class="f-ipt" v-model="fieldItem.imageUploadLimit"
                                                              :disabled="commonDisabled"
                                                              placeholder="0"></el-input>张</div>
                                        <span style="font-size: 12px;color: grey">（上传数量为0则不限制）</span>
                                    </div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 省市区 -->
                            <div class="f-block" v-if="fieldItem.type == 8">
                                <div class="flex-depart-center">
                                    <div class="tl-item flex-depart-left">
                                        {{fieldIndex+1}}、
                                        <el-input class="f-ipt" v-model="fieldItem.fieldName"
                                                  :disabled="commonDisabled"
                                                  placeholder="请选择省市区"></el-input>
                                    </div>
                                    <div class="f-handle tr-item" v-if="fieldIndex != 0">
                                        <img :src="imgUpMoveSrc" alt="" @click="topNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgDownMoveSrc" alt="" @click="bottomNuoPng(fieldItem,fieldIndex,2,listIndex)">
                                        <img :src="imgCancelSrc" alt="" @click="delMuKuai(fieldItem,fieldIndex,listIndex)" v-show="commonDisabled == false">
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10">
                                    <div>
                                        <span>文本格式：</span>
                                        <el-select v-model="fieldItem.areaSelectType" placeholder="请选择" @change="$event => bindValidateAreaPickerChange($event,fieldIndex,listIndex)">
                                            <el-option
                                                    v-for="item in areaSetList"
                                                    :key="item.type"
                                                    :label="item.value"
                                                    :value="item.type">
                                            </el-option>
                                        </el-select>
                                        <el-checkbox style="margin-left: 15px" v-model="fieldItem.isUniqueCheckChecked" @change="$event => isUniqueCheckChange($event,fieldIndex,listIndex)">不可以重复</el-checkbox>
                                    </div>
                                    <div class="flex-depart-center-end checkedState">
                                        <el-checkbox v-model="fieldItem.isRequiredChecked" @change="$event => isRequiredChange($event,fieldIndex,listIndex)">必填</el-checkbox>
                                        <el-checkbox v-model="fieldItem.isOpenChecked" @change="$event => isOpenChange($event,fieldIndex,listIndex)">公开</el-checkbox>
                                        <div class="flex-depart-left ch-copy" @click="copyThisSignField(fieldIndex,listIndex)">
                                            <i class="el-icon-document-copy"></i>复制
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="b-marT-20 flex-depart-center-center">
                        <div class="addBtnStyle" @click="openfieldList"><i class="el-icon-circle-plus-outline"></i>添加填表项</div>
                    </div>
                </div>
                <!-- todo 设置 -->
                <template>
                    <div class="set-out-box">
                        <div class="cannot-edit" @click="cannotEdit" v-if="commonDisabled == true"></div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">接龙起止时间</span>
                            <div>
                                <el-date-picker
                                        v-model="actStartAndEndTime"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        @change="actStartAndEndTimeFun">
                                </el-date-picker>
                            </div>
                        </div>
                        <!-- -------------------打卡部分开始---------------------- -->
                        <div v-if="isType == '109' || isType == '110'">
                            <div class="settingBox b-marT-10 flex-depart-center">
                                <span class="sTitle">打卡提醒</span>
                                <div>
                                    <el-radio-group v-removeAriaHidden v-model="formClock.clockRemindStatus" @input="clockRemindFun">
                                        <el-radio :label="1">开启</el-radio>
                                        <el-radio :label="2">关闭</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="settingBox flex-depart-center" style="padding-top: 0" v-if="formClock.clockRemindStatus == 1">
                                <span class="sTitle">打卡提醒时间</span>
                                <div>
                                    <el-time-picker
                                            v-model="formClock.remindTime"
                                            format="HH:mm"
                                            value-format="HH:mm"
                                            placeholder="任意时间点"
                                            @change="clockTime">
                                    </el-time-picker>
                                </div>
                            </div>
                            <div class="settingBox b-marT-10 flex-depart-center">
                                <span class="sTitle">打卡频率</span>
                                <div>
                                    <el-select v-model="selectFrequency" placeholder="请选择" @change="clickin">
                                        <el-option
                                                v-for="item in CheckInFrequency"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="settingBox flex-depart-center" v-if="selectFrequency == 6">
                                <el-checkbox-group v-model="candidateList" @change="candidateChange">
                                    <el-checkbox v-for="(item) in candidates" :key="item.value" :label="item.value">{{ item.name }}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div class="settingBox b-marT-10 flex-depart-center" v-if="selectFrequency == 7">
                                <span class="sTitle">打卡日期选择<label class="cus-dates">已选择{{ customDateArr.length }}个日期</label></span>
                                <div>
                                    <el-date-picker
                                            type="dates"
                                            v-model="customDateArr"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择一个或多个日期"
                                            @change="multipleDateSure">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="settingBox b-marT-10 " v-if="selectFrequency == 1 || selectFrequency == 2">
                                <div class="flex-depart-center">
                                    <span class="sTitle">允许每{{ selectFrequency == 1?'月':'周' }}多次打卡</span>
                                    <div>
                                        <el-radio-group v-removeAriaHidden v-model="formClock.clockMaxSwitch" @input="clockMaxSwitchFn">
                                            <el-radio :label="1">开启</el-radio>
                                            <el-radio :label="2">关闭</el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="flex-depart-center" style="padding-top: 10px" v-if="(selectFrequency == 1 || selectFrequency == 2) && formClock.clockMaxSwitch == 1">
                                    <div>每{{ selectFrequency == 1?'月':'周' }}打卡</div>
                                    <div>
                                        <el-input style="width: 100px" v-model="formClock.clockMaxNum" placeholder="请输入内容"></el-input>&nbsp;次
                                    </div>
                                </div>
                            </div>
                            <div class="settingBox b-marT-10 flex-depart-center" v-if="selectFrequency == 1 || selectFrequency == 2">
                                <span class="sTitle">每{{ selectFrequency == 1?'月':'周' }}截止打卡时间</span>
                                <div class="flex-depart-center-end">
                                    <div class="deadline" v-if="selectFrequency == 1">
                                        <el-select v-model="monthDay" placeholder="请选择" @change="endMonthFunFirst">
                                            <el-option
                                                    v-for="item in endMonthArr1"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="deadline" v-if="selectFrequency == 2">
                                        <el-select v-model="weekDay" placeholder="请选择" @change="endWeekFunFirst">
                                            <el-option
                                                    v-for="item in endWeekArr1"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="deadline">
                                        <el-select v-model="monthHour" placeholder="请选择" @change="endMonthFunSecond">
                                            <el-option
                                                    v-for="item in endMonthArr2"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="deadline">
                                        <el-select v-model="monthMinute" placeholder="请选择" @change="endMonthFunThird">
                                            <el-option
                                                    v-for="item in endMonthArr3"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>

                                </div>
                            </div>

                            <div class="settingBox b-marT-10" v-if="selectFrequency == 3 || selectFrequency == 4 || selectFrequency == 5 || selectFrequency == 6 || selectFrequency == 7">
                                <div class="flex-depart-center">
                                    <span class="sTitle">多时段打卡</span>
                                    <div>
                                        <el-radio-group v-removeAriaHidden v-model="formClock.multiPeriodSwitch" @input="clockMultiPeriodSwitchFn">
                                            <el-radio :label="1">开启</el-radio>
                                            <el-radio :label="2">关闭</el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="b-marT-10">打卡时间段:</div>
                                <div class="flex-depart-left b-marT-10 rangeTimes"
                                     v-for="(item,index) in formClock.clockInTimeSlot" :key="index" v-show="item.ifDel == 2">
                                    <el-time-picker
                                            is-range
                                            v-model="item.startAndEnd"
                                            format="HH:mm"
                                            value-format="HH:mm"
                                            range-separator="至"
                                            start-placeholder="开始时间"
                                            end-placeholder="结束时间"
                                            placeholder="选择时间范围"
                                            @change="e => clockInTimeSlot(e,index)">
                                    </el-time-picker>
                                    <i class="el-icon-delete-solid" @click="timesDel(index)"></i>
                                </div>
                                <div class="b-marT-10 add-rangeTimes" @click="addTimes" v-if="formClock.multiPeriodSwitch == 1">添加时间段</div>
                            </div>
                            <div class="settingBox b-marT-10 ">
                                <div class="flex-depart-center">
                                    <span class="sTitle">指定打卡地点</span>
                                    <div>
                                        <el-radio-group v-removeAriaHidden v-model="formClock.clockLocationStatus" @input="clockLocationFn">
                                            <el-radio :label="1">开启</el-radio>
                                            <el-radio :label="2">关闭</el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="flex-depart-center b-marT-10" v-if="formClock.clockLocationStatus == 1">
                                    <span>参与地点</span>
                                    <div>
                                        {{ formClock.address }}
                                        <el-button @click="openAddress()">选择位置</el-button>
                                    </div>
                                </div>

                                <div class="flex-depart-center b-marT-10" v-if="formClock.clockLocationStatus == 1">
                                    <span>打卡范围</span>
                                    <div>
                                        <el-select v-model="formClock.clockRange" placeholder="请选择" @change="clockRangeChange">
                                            <el-option
                                                    v-for="item in dakaColumnsArr"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- -----------------打卡部分结束------------------------- -->
                    </div>


                    <div class="settingBox b-marT-10 flex-depart-center seniorStyle" @click="seniorShow = !seniorShow">
                        <span class="sTitle"><b></b>高级设置</span>
                        <div>
                            <i class="el-icon-arrow-up" v-if="!seniorShow"></i>
                            <i class="el-icon-arrow-down" v-else></i>
                        </div>
                    </div>
                    <div class="set-out-box" v-show="seniorShow == true">
                        <div class="cannot-edit" @click="cannotEdit" v-if="commonDisabled == true"></div>
                        <div class="settingBox b-marT-10 ">
                            <div class="flex-depart-center">
                                <span class="sTitle">显示发布人信息</span>
                                <div>
                                    <template>
                                        <el-radio-group v-removeAriaHidden v-model="isanonymous" @input="anonymousFun">
                                            <el-radio :label="1">开启</el-radio>
                                            <el-radio :label="0">关闭</el-radio>
                                        </el-radio-group>
                                    </template>
                                </div>
                            </div>
                            <div class="b-marT-10" v-if="isanonymous == 1">
                                发布人：<el-input style="width: 94%" v-model="publisher" placeholder="请输入内容"></el-input>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">禁止他人复制该接龙</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="copyStatus" @input="copyStatusFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="2">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">禁止他人转发该接龙</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="is_forward" @input="forwardFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="0">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">接龙后自定义展示页面</span>
                            <div class="setText" @click="openCus">设置</div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">匿名参与</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="anonymousparticipation" @input="anonymouspartFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="0">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 ">
                            <div class="flex-depart-center">
                                <span class="sTitle">指定参与地点</span>
                                <div>
                                    <el-radio-group v-removeAriaHidden v-model="is_destination" @input="isDestinationFun">
                                        <el-radio :label="1">开启</el-radio>
                                        <el-radio :label="0">关闭</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="flex-depart-center b-marT-10" v-if="is_destination == 1">
                                <span>参与地点</span>
                                <div>
                                    {{ specifyaddress }}
                                    <el-button @click="openJoininAddress()">选择位置</el-button>
                                </div>

                            </div>

                            <div class="flex-depart-center b-marT-10" v-if="is_destination == 1">
                                <span>参与范围</span>
                                <div>
                                    <el-select v-model="checkinrange" placeholder="请选择" @change="checkinRangeChange">
                                        <el-option
                                                v-for="item in addresscolumnsArr"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">参与接龙按钮文字</span>
                            <div class="flex-depart-center-end">
                                <el-input style="width: 200px" v-model="confirmWord" placeholder="请输入内容"></el-input>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">提交表单按钮文字</span>
                            <div class="flex-depart-center-end">
                                <el-input style="width: 200px" v-model="submitcopy" placeholder="请输入内容"></el-input>
                            </div>
                        </div>

                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">开启留言</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="commentStatus" @input="commentFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="2">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">点赞</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="thumbs_up" @input="thumbsUpFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="2">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">点评</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="is_comment" @input="isCommentFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="2">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">评分</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="is_score" @input="isScoreFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="2">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">报名信息展示</span>
                            <div>
                                <el-radio-group v-removeAriaHidden v-model="signInfoStatus" @input="signInfoIsOpenFun">
                                    <el-radio :label="1">开启</el-radio>
                                    <el-radio :label="2">关闭</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">允许修改报名信息</span>
                            <div>
                                <el-select v-model="updateSignInfo" placeholder="请选择" @change="signChange">
                                    <el-option
                                            v-for="item in updateSignArr"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="settingBox flex-depart-center" style="padding-top: 0" v-show="updateSignInfo == 2">
                            <span class="sTitle">时间范围</span>
                            <div>
                                <el-date-picker
                                        v-model="deadline"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        @change="deadlineTimeFun">
                                </el-date-picker>
                            </div>
                        </div>

                        <div class="settingBox b-marT-10 flex-depart-center">
                            <span class="sTitle">报名后是否允许取消</span>
                            <div>
                                <el-select v-model="removeSignStatus" placeholder="请选择" @change="removeSignChange">
                                    <el-option
                                            v-for="item in cancellationArr"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="settingBox flex-depart-center" style="padding-top: 0" v-if="removeSignStatus == 3">
                            <span class="sTitle">时间范围</span>
                            <div>
                                <el-date-picker
                                        v-model="deadCancelline"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        @change="deadCancelTimeFun">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="settingBox b-marT-10 flex-depart-center ">
                            <span class="sTitle">限制参与人数</span>
                            <div class="flex-depart-center-end danwei">
<!--                                <el-input style="width: 200px" v-model="interdict_people_num"-->
<!--                                          @input="interdict_people_num=interdict_people_num.replace(/^\.+|[^\d]+/g,'')"-->
<!--                                          @focus="FocusInterdictPeople()" @blur="BlurInterdictPeople" placeholder="请输入内容"></el-input>&nbsp;人-->
                                <input type="text" v-model="interdict_people_num"
                                       @input="interdict_people_num=interdict_people_num.replace(/^\.+|[^\d]+/g,'')"
                                       @focus="FocusInterdictPeople()" @blur="BlurInterdictPeople" placeholder="请输入参与人数">&nbsp;人
                            </div>
                        </div>
                    </div>

                </template>


            </div>
        </div>

        <!-- todo 去创建 -->
        <div class="posi-bottom-btn-box">
            <div class="saveDatabox flex-depart-center-center" @click="submit">
                保存并预览
            </div>
        </div>


        <div style="height: 90px"></div>
        <!--查看大图-->
        <div class="previewImgBox" @click="hidePreview" v-if="previewImgShow == true">
            <i class="closePImg el-icon-circle-close"></i>
            <img class="inner-p-img" :src="imageUrl + previewImgSrc" alt="">
        </div>
        <!--抽屉-表单项弹框 -->
        <el-drawer
                :visible.sync="formDrawerShow"
                :direction="direction"
                size="450px"
                :withHeader="false"
                :before-close="formDrawerClose">
            <div class="drawerOutBox">
                <div class="drawer-nav-top">
                    <div></div>
                    <span>添加表单项</span>
                    <i class="el-icon-close" @click="formDrawerClose"></i>
                </div>
                <div class="af-title ">通用填写项</div>
                <div class="ty-block flex-depart-center b-marT-10">
                    <div class="ty-b-item" @click="addForm(-1)">
                        <i class="el-icon-circle-plus addFBtn"></i>
                        <div>单行文本</div>
                        <p>姓名、填空等单行文本</p>
                    </div>
                    <div class="ty-b-item" @click="addForm(-4)">
                        <i class="el-icon-circle-plus addFBtn"></i>
                        <div>多行文本</div>
                        <p>可输入多行文字</p>
                    </div>
                </div>
                <div class="ty-block flex-depart-center b-marT-10">
                    <div class="ty-b-item" @click="addForm(-2)">
                        <i class="el-icon-circle-plus addFBtn"></i>
                        <div>单选</div>
                        <p>单向选择</p>
                    </div>
                    <div class="ty-b-item" @click="addForm(-3)">
                        <i class="el-icon-circle-plus addFBtn"></i>
                        <div>多选</div>
                        <p>多项选择</p>
                    </div>
                </div>
                <div class="af-title b-marT-10">常用填写项</div>
                <div class="b-marT-10 cy-box">
                    <div class="cy-item" v-for="(item,index) in formList" :key="item.type" @click="addForm(item.type,index)">
                        <span>{{ item.label }}</span><i class="el-icon-circle-plus addFBtn2"></i>
                    </div>
                </div>
            </div>
        </el-drawer>
        <!--抽屉-名单 -->
        <el-drawer
                :visible.sync="openNameListDrawer"
                :direction="direction"
                size="450px"
                :withHeader="false"
                :before-close="nameListClose">
            <div class="drawerOutBox">
                <div class="drawer-nav-top">
                    <div></div>
                    <span>添加接龙名单</span>
                    <i class="el-icon-close" @click="nameListClose"></i>
                </div>
                <div class="nl-title">姓名名单 <span>合计:{{people_num}}人</span></div>
                <div class="b-marT-10">
                    <el-input type="textarea" rows="15" v-model="nameShowContent"
                              placeholder="请输入姓名名单，每个名单可用中英文逗号或换行隔开，最大输入为100个名单。可复制粘贴" class="textareName"></el-input>
                </div>
                <div class="flex-depart-center b-marT-10">
                    <div class="daoru" @click="importVisible = true">从Excel导入</div>
                    <div class="qingkong" @click="clearName"><i class="el-icon-delete"></i>清空内容</div>
                </div>
                <div class="flex-depart-center b-marT-20 addnameBtns">
                    <el-button round @click="openNamelistFun">预存名单</el-button>
                    <el-button round type="primary" @click="sureAddNameList">确定</el-button>
                </div>
            </div>
        </el-drawer>
        <!-- 弹出层-名单导入 -->
        <el-dialog
                title="Excel表格导入"
                :visible.sync="importVisible"
                width="30%"
                :before-close="importClose">
            <div>
                <el-button @click="downExam">下载excel模板</el-button>
                <el-button type="primary" class="updateFileBtn-out">
                    <input class="updateFileBtn" type="file" id="imageInput7" accept=".xls,.xlsx" @change.stop="importExcel"/>导入
                </el-button>
                <div class="b-marT-10" style="color: #F56C6C">注：Excel表格内的内容默认只读取第一列非第一行的内容</div>
            </div>
        </el-dialog>

        <!--抽屉-预存名单列表 -->
        <el-drawer
                :visible.sync="nameListDrawer"
                :direction="direction"
                size="450px"
                :withHeader="false"
                :before-close="nameListDrawerFun">
            <div class="drawerOutBox">
                <div class="drawer-nav-top">
                    <div></div>
                    <span>预存名单列表</span>
                    <i class="el-icon-close" @click="nameListDrawerFun"></i>
                </div>
                <div class="flex-depart-center b-marT-10 ycn" v-for="(item,index) in presetsList" :key="index" @click="EchoList(item)">
                    <div class="n-name">{{item.clockPresetTitle}}</div>
                    <div class="n-handle">
                        <span @click.stop="nameLists(item.id)">编辑</span>
                        <span @click.stop="presetsDel(item.id)">删除</span>
                    </div>
                </div>
                <el-button type="primary" class="b-marT-10" round @click="toAddRoster">添加姓名名单</el-button>
            </div>
        </el-drawer>
        <!--抽屉-添加预存名单 -->
        <el-drawer
                :visible.sync="addNameListDrawer"
                :direction="direction"
                size="450px"
                :withHeader="false"
                :before-close="addNameDrawer">
            <div class="drawerOutBox">
                <div class="drawer-nav-top">
                    <div></div>
                    <span>添加预存名单</span>
                    <i class="el-icon-close" @click="addNameDrawer"></i>
                </div>
                <div class="nl-title">名单标题</div>
                <div class="b-marT-10">
                    <el-input v-model="clockShowPresetTitle" @input="handlerBlur()" placeholder="请输入内容"></el-input>
                </div>
                <div class="nl-title b-marT-10">预存名单 <span>合计:{{people_num2}}人</span></div>
                <div class="b-marT-10">
                    <el-input type="textarea" rows="15" v-model="clockShowPresetContent"
                              placeholder="请输入姓名名单，每个名单可用中英文逗号或换行隔开，最大输入为100个名单。可复制粘贴" class="textareName"></el-input>
                </div>
                <div class="flex-depart-center b-marT-10">
                    <div class="daoru updateFileBtn-out">
                        从Excel导入
                        <input class="updateFileBtn" type="file" id="imageInput8" accept=".xls,.xlsx" @change.stop="importFiles"/>
                    </div>
                    <div class="qingkong" @click="emptyName"><i class="el-icon-delete"></i>清空内容</div>
                </div>
                <div class="flex-depart-center-end b-marT-10 drawBottomBtn">
                    <el-button @click="closeAddNameDrawer">取消</el-button>
                    <el-button type="primary" @click="clockPresetsAdd">确定</el-button>
                </div>
            </div>
        </el-drawer>
        <!--抽屉-接龙后自定义展示页面 -->
        <el-drawer
                :visible.sync="customizeDrawer"
                :direction="direction"
                size="450px"
                :withHeader="false"
                :before-close="customizeClose">
            <div class="drawerOutBox">
                <div class="drawer-nav-top">
                    <div></div>
                    <span>接龙后自定义展示页面</span>
                    <i class="el-icon-close" @click="customizeClose"></i>
                </div>
                <div class="duiHao">
                    <img src="https://ossfastmake.mustke.com//12jielong/lsyJL/successdh.png" alt="">
                </div>
                <div class="b-marT-10 cw">
                    <el-input v-model="copyShowWriting" placeholder="请输入自定义文字"></el-input>
                </div>
                <div class="b-marT-20 flex-depart-center-center" v-if="!copyShowImage">
                    <div class="upl-smallImg upl-smallImg2">
                        <input class="updateFileBtn" type="file" accept="image/*" id="imageInput9" @change="uploadCopyImage"/>
                        <i class="el-icon-circle-plus"></i>
                        <span>点击上传图片</span>
                    </div>
                </div>
                <div class="b-marT-20 flex-depart-center-center copy-image-out" v-if="copyShowImage">
                    <img src="https://ossfastmake.mustke.com//static/0307/del.svg" @click.stop="delCopyImage" alt="" class="del-copy-img">
                    <img class="copy-image" :src="imageUrl + copyShowImage" alt="">
                </div>
                <div class="flex-depart-center-end b-marT-10 drawBottomBtn">
                    <el-button @click="customizeDrawer = false">取消</el-button>
                    <el-button type="primary" @click="sureCoverImgFun">确定</el-button>
                </div>
            </div>
        </el-drawer>

        <!-- 地图 -->
        <el-dialog title="请选择地址" width="600px" :visible.sync="addressDialog" :destroy-on-close="true" top>
            <iframe id="mapPage2" width="100%" height="100%" style="width: 550px; height:500px" frameborder=0
                    src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=NBJBZ-IMQLX-TPL4O-7KADZ-F6HMT-V3BPM&referer=myapp">
            </iframe>
            <span slot="footer">
                <el-button @click="addressDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitMap">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="音乐" width="600px" :visible.sync="musicDialog" :destroy-on-close="true" center>
            <audio controls :src="musicLink" type="audio/mp3"></audio>
        </el-dialog>

    </Layout>
</template>
<style lang="scss" scoped>
    .b-marT-10{
        margin-top: 10px;
    }
    .b-marT-20{
        margin-top: 20px;
    }
    /* flex布局 */
    .flex-depart-left{
        display: flex;
        align-items: center;
    }
    .flex-depart-center{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .flex-depart-center-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .flex-depart-center-end{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .flex-depart-top{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .flex-depart-left-warp{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    /*大图排序*/
    .flex-depart-column-top-left{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }


    .outBox{
        width: 1000px;
        margin: 0 auto;
        background-color: #FBFBFB;
    }
    .bigTitle{
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: white;
        font-weight: bold;
        background-color: #1877FF;
    }
    .block-title{
        font-size: 14px;
        font-weight: Bold;
        text-align: left;
        color: #191919;
    }
    .blockBox{
        padding: 15px;
        background-color: white;
        margin-bottom: 10px;
        position: relative;
    }

    .cannot-edit {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        height: 100%;
        width: 100%;
    }


    .handle-box .h-item {
        margin-right: 30px;
        cursor: pointer;
    }
    .handle-box .h-item img{
        width: 20px;
        height: 20px;
        margin-right: 1px;
    }
    .handle-box .h-item span{
        font-size: 14px;
        color: #767d98;
    }
    .addBtnStyle{
        width: 395px;
        height: 43px;
        background: #ffffff;
        border: 1px dashed #4a73f7;
        border-radius: 23px;
        font-size: 16px;
        color: #1877ff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i{
            margin-right: 10px;
        }
    }
    .f-block{
        background: #fafafa;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 10px;
        margin-top: 10px;
    }
    .f-block:last-child{
        /*margin-bottom: 0;*/
    }
    .tl-item .f-ipt{
        width: 700px;
    }
    .f-handle img{
        width: 20px;
        height: 20px;
        margin-left: 5px;
        cursor: pointer;
    }
    .checkedState .el-checkbox{
        margin-bottom: 0;
    }
    .ch-copy{
        margin-left: 50px;
        font-size: 13px;
        color: #409EFF;
        align-items: center;
        cursor: pointer;
        i{
            /*width: 17px;*/
            /*height: 17px;*/
            margin-right: 4px;
        }

    }
    .selectBox-item{
        padding-left: 20px;
    }
    .selectBox-item .el-input{
        width: 700px;
        margin-right: 10px;
    }
    .selectBox-item i{
        font-size: 18px;
    }
    .addOptStyle{
        color: #1877ff;
        cursor: pointer;
        padding-left: 20px;
    }
    .settingBox{
        padding: 10px 15px;
        background-color: white;
        position: relative;
    }
    .sTitle{
        font-size: 14px;
        font-weight: Bold;
        text-align: left;
        color: #191919;
        display: inline-flex;
        align-items: center;
        label{
            font-size: 12px;
            color: grey;
            font-weight: normal;
        }
        b{
            width: 4px;
            height: 14px;
            background: #1877ff;
            border-radius: 2px;
            margin-right: 5px;
        }
    }
    .settingBox .el-date-editor .el-range-separator {
        width: 15%;
    }
    .setText{
        color: #1877ff;
        cursor: pointer;
        text-decoration: underline;
    }
    .settingBox .el-radio{
        margin-bottom: 0;
    }
    .block-for-box{
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        margin-top: 10px;
    }
    .big-img img{
        max-width: 600px;
        height: auto;
    }
    .b-handle img{
        width: 20px;
        height: 20px;
        margin: 5px;
        cursor: pointer;
    }
    .addSmallBtn{
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F7F7F7;
        color: #1877ff;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

    }
    .updateFileBtn{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }
    .rangeTimes i{
        margin-left: 5px;
        font-size: 25px;
        cursor: pointer;
        color: #F56C6C;
    }

    .add-rangeTimes{
        color: #1877ff;
        cursor: pointer;
    }
    .map {
        width: 100%;
        height: 300px;
    }
    .deadline{
        width: 80px;
        margin-left: 5px;
    }
    .smallImgbox{
        width: 150px;
        height: 150px;
        background-color: #F7F7F7;
        margin: 5px;
        position: relative;
    }
    .handle2{
        width: 150px;
        height: 150px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.5);
        display: none;
        img{
            width: 20px;
            height: auto;
            cursor: pointer;
            margin-top: 70px;
            margin-left: 33px;
        }
    }
    .seeBigImgHover:hover{
        .handle2{
            display: block;
        }
    }
    .imgBg{
        width: 150px;
        height: 150px;
        /*background: url("https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg") no-repeat center;*/
        background-size: cover;

    }
    .upl-smallImg{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
        background-color: #F7F7F7;
        color: #1877ff;
        cursor: pointer;
        margin: 5px;
        position: relative;
        i{
            font-size: 26px;
        }
    }
    .upl-smallImg2{
        width: 100%;
        height: 200px;
        margin: 0;
    }
    .filesStyle{
        height: 45px;
        .tubiao{
            margin-right: 2px;
        }
        .del{
            margin-left: 10px;
            position: relative;
            top: 1px;
            cursor: pointer;
        }
        img{
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
    .previewImgBox{
        width: 100%;
        height: 100%;
        z-index: 99999;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .inner-p-img{
            height: 600px;
            width: auto;
        }
        .closePImg{
            font-size: 40px;
            cursor: pointer;
            position: fixed;
            top: 40px;
            right: 40px;
            color: white;
        }
    }
    .drawerOutBox{
        padding: 0 20px;
        height: 100%;
        position: relative;
    }
    .drawBottomBtn{
        width: 100%;
        height: 70px;
        position: absolute;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
        padding-right: 20px;
    }
    .af-title{
        font-weight: bold;
    }
    .ty-b-item{
        position: relative;
        width: 195px;
        height: 80px;
        border: 1px solid #B2C4FB;
        background-color: #F4F7FE;
        border-radius: 5px;
        box-sizing: border-box;
        padding-top: 18px;
        padding-left: 10px;
        cursor: pointer;
    }
    .ty-b-item div{
        font-weight: bold;
        color: #1877FF;
    }
    .addFBtn{
        color: #1877FF;
        font-size: 22px;
        position: absolute;
        right: 10px;
        top: 18px;
    }
    .cy-box{
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .cy-item{
        width: 128px;
        height: 45px;
        box-sizing: border-box;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F4F7FE;
        border-radius: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        .addFBtn2{
            color: #1877FF;
            font-size: 22px;
        }
    }
    .seniorStyle{
        background-color: #ECF4FF !important;
        cursor: pointer;
    }
    .cus-dates{
        margin-bottom: 0 !important;
        margin-left: 5px;
    }
    .nl-title{
        font-weight: bold;
        span{
            font-size: 12px;
            font-weight: normal;
            color: grey;
        }
    }
    .daoru{
        color: #1877FF;
        cursor: pointer;
        position: relative;
        text-decoration: underline;
    }
    .qingkong{
        cursor: pointer;
        color: red;
    }
    .ycn{
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        box-sizing: border-box;
        background-color: #FBFBFB;
        border: 1px solid #d4d4d4;
        .n-name{
            width: 50%;
            word-break: break-word;
        }
        .n-handle{
            span{
                color: #1877FF;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
    .duiHao{
        text-align: center;
        img{
            height: 80px;
            width: 80px;
        }
    }
    .updateFileBtn-out{
        position: relative;
    }
    .copy-image-out{
        position: relative;
    }
    .del-copy-img{
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .copy-image{
        width: 100%;
        height: auto;
    }
    .posi-bottom-btn-box{
        position: fixed;
        left: 187px;
        right: 0;
        bottom: 0;
        height: 160px;
        z-index: 999;
    }
    .saveDatabox{
        width: 416px;
        height: 50px;
        background: #1877ff;
        border-radius: 25px;
        font-size: 16px;
        font-weight: Bold;
        color: #ffffff;
        margin: 30px auto;
        cursor: pointer;
    }
    .s-name-item{
        width: 105px;
        height: 45px;
        background: #f2f2f2;
        border-radius: 4px;
        display: inline-block;
        text-align: center;
        line-height: 45px;
        margin-right: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
    }
    .set-out-box{
        position: relative;
        height: auto;
    }
    .centerTitle input{
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        /*transition: border-color .2s cubic-bezier(.645,.045,.355,1);*/
        width: 100%;
    }
    .centerTitle input:focus{
        border-color: #409EFF;
    }
    .centerTitle input::-webkit-input-placeholder {
        /* placeholder颜色 */
        color: #C4C4CC;
        /* placeholder字体大小 */
        font-size: 14px;
    }
    .centerTitle{
        :deep(.el-input__inner)::-webkit-input-placeholder{
            text-align: center;
            font-size: 16px;
        }
    }
    .addnameBtns button{
        width: 45%;
    }
    .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin: 5px 0;
    }
    .hoverColor1:hover{
        background-color: #fafafa;
    }
    .hoverColor1:hover .del{
        color: red;
    }
    .drawer-nav-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        span{
            font-size: 18px;
            color: black;
            font-weight: bold;
        }
        i{
            cursor: pointer;
            font-size: 18px;
        }
    }

    .danwei{
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        box-sizing: border-box;

        /*display: inline-block;*/
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        width: 210px;
        input{
            color: #606266;
            width: 170px;
            height: 38px;
            border: 0;
            outline: none;
            box-sizing: border-box;
            padding-left: 10px;
            /*background-color: red;*/
        }
    }
    .set-out-box .settingBox:hover{
        background-color: #f4f4f4;
    }
    .blockBox{
        :deep(.el-input__inner):hover{
            border: 1px solid #409EFF !important;
        }
        :deep(.el-textarea__inner):hover{
            border: 1px solid #409EFF !important;
        }


    }
    .settingBox{
        :deep(.el-input__inner):hover{
            border: 1px solid #409EFF !important;
        }
    }
    .danwei:hover{
        border: 1px solid #409EFF !important;
    }
    .cw :deep(.el-input__inner){
        text-align: center;
    }
    .el-input{
        :deep(.el-input__inner):hover{
            border: 1px solid #409EFF !important;
        }
    }
    .bf-item{
        :deep(.el-input__inner){
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
        }
    }
    .bf-item{
        :deep(.el-input__inner):hover{
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            border-radius: 0 !important;
        }
    }

    .zsy{
        :deep(.el-textarea__inner){
            overflow-y: hidden;
            height: 164px;
            resize: none;
            min-height: 76px;
            /*max-height: 450px;*/
        }
    }
    .nozsy{
        :deep(.el-textarea__inner){
            overflow-y: auto;
            height: 164px !important;
            min-height: 76px;
            /*max-height: 450px;*/
        }
    }


</style>